import { Component, Input, ViewChild, ElementRef, NgZone, OnDestroy, Renderer2, OnInit, ChangeDetectionStrategy, OnChanges, SimpleChanges, AfterViewInit, EventEmitter, Output, TemplateRef, Inject, ChangeDetectorRef, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import KeenSlider, { TOptionsEvents } from 'keen-slider';
import { IntersectionObserverService } from '@ng-web-apis/intersection-observer';
import {PlatformService} from '../../services/platform.service';
import {VisibilityService} from '../../services/visibility.service';
import { NgClass } from '@angular/common';

export type CustomConfig = {autoplay?: boolean, delay: number, imgLoaded: boolean, isZoom: boolean};
export type SliderConfig = Partial<TOptionsEvents & CustomConfig>;

@Component({
    selector: 'app-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        IntersectionObserverService,
    ],
    standalone: true,
    imports: [NgClass]
})
export class SliderComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  private _slider: KeenSlider = null;
  private _interval: any = 0;
  private _pause: boolean = false;
  private _currentSlide: number = 0;

  private _defaultConfig = {
    root: null,
    rootMargin: '0px',
    threshold: 0.0,
    loop: true,
    slidesPerView: 1,
    autoplay: true,
    delay: 5000,
    breakpoints: {
      '(max-width: 720px)': {
        slidesPerView: 1,
      },
    },
    imgLoaded: true
  };

  @ViewChild('track', {static: true}) protected track: ElementRef<HTMLElement>;
  @ViewChild('pagination', {static: true}) protected pagination: TemplateRef<HTMLElement>;

  @HostListener('touchend', ['$event']) onTouch(event: Event): void {
    if (event) {
      this._runDetectChanges();
    }
  }

  /**
   * Custom slider class
   */
  @Input('class') class: string | string[] = '';

  /**
   * Slider list data
   */
  @Input('slideList') slideList: any[] = [];

  /**
   * Slider config
   */
  @Input('config') config: SliderConfig = {};

  /**
   * Use intersection Observer
   */
  @Input('intersectionObserver') intersectionObserver = false;

  /**
   * If current browser tab active subscription
   */
  private _pageIsVisible$: Subscription = null;

  /**
   * Slider event emitters
   */
  @Output('mounted') mounted$: EventEmitter<any> = new EventEmitter();
  @Output('slideChanged') slideChanged$: EventEmitter<any> = new EventEmitter();

  constructor(
    private _platform: PlatformService,
    private _zone: NgZone,
    private _render: Renderer2,
    private _visibility: VisibilityService,
    private _cd: ChangeDetectorRef,
    @Inject(IntersectionObserverService) private _entries$: IntersectionObserverService,
  ) {

  }

  public get slider(): KeenSlider {
    return this._slider;
  }

  public get paginationElement() {
    return this.pagination;
  }

  public get currentSlide() {
    return this._currentSlide;
  }

  ngOnInit() {
    if (this._platform.isBrowser) {
      this.config = {...this._defaultConfig, ...this.config};
    }
  }
  scaleStyle(idx) {
    if (!this.config.isZoom || this.slider === null || undefined) return {};
    const slide = this._slider.details().positions[idx];
    const scaleSize = 0.7;
    const scale = 1 - (scaleSize - scaleSize * this._slider.details().positions[idx].portion);
    return {
      transform: `scale(${scale})`,
      WebkitTransform: `scale(${scale})`,
    };
  }

  ngAfterViewInit() {
    if (this._platform.isBrowser) {
      this._zone.run(() => {
        try {
          if (this.intersectionObserver) {
            this._entries$.subscribe(e => {
              if (e[0].isIntersecting) {
                setTimeout(() => {
                  this._addSlideItemClass();
                  this._initSlider();
                  this._runSliderEmitters();
                }, 100);
              } else {
                this._destroySlider();
              }
            });
          } else {
            setTimeout(() => {
              this._addSlideItemClass();
              this._initSlider();
              this._runSliderEmitters();
            }, 100);
          }
        } catch (error) {
          setTimeout(() => {
            this._addSlideItemClass();
            this._initSlider();
            this._runSliderEmitters();
          }, 100);
        }
      });
    }
  }

  /**
   * Initialize slider
   */
  private _initSlider() {
    this._slider = new KeenSlider(this.track.nativeElement, {
      ...this.config,
      mounted: slider => {
        this.mounted$.next(slider.details());
        this._currentSlide = slider.details().relativeSlide;
        this.slideChanged$.next(this._currentSlide);
        this._runDetectChanges();
      },
      slideChanged: slider => {
        this._currentSlide = slider.details().relativeSlide;
        this.slideChanged$.next(this._currentSlide);
        this._runDetectChanges();
      },
      dragStart: () => {
        this.setPause(true);
      },
      dragEnd: () => {
        this.setPause(false);
      },
    });
    this._runDetectChanges();
  }

  /**
   * Run slider emitters
   */
  private _runSliderEmitters() {
    if (this._slider) {
      this._addImgClassLoaded();
      this._visibilityEmiter();
    }
  }

  /**
   * Add class on slide element before slider init
   */
  private _addSlideItemClass() {
    this.track.nativeElement.childNodes.forEach(slide => {
      if (slide.nodeType !== Node.COMMENT_NODE) {
        this._render.addClass(slide, 'keen-slider__slide');
      }
    });
  }

  /**
   * Add image class loaded
   */
  private _addImgClassLoaded() {
    if (this.config.imgLoaded) {
      this.track.nativeElement.querySelectorAll('img')
        .forEach((img: HTMLImageElement) => this._render.addClass(img, 'loaded'));
    }
  }

  /**
   * Run slider autoplay and mouse events
   */
  private _setAutoPlay() {
    this._resetInterval();
    this._interval = setInterval(() => {
      if (!this._pause) {
        this.slider.next();
      }
    }, this.config.delay);
  }

  /**
   * Set slider autoplay state
   * @param active
   */
  public setPause(active: boolean) {
    if (this.config.autoplay) {
      this._pause = active;
      this._setAutoPlay();
    }
  }

  /**
   * Reset slider interval
   */
  private _resetInterval() {
    clearInterval(this._interval);
  }

  /**
   * If current tab in browser not active, it set pause slider autoplay
   */
  private _visibilityEmiter() {
    if (this.config.autoplay) {
      this._pageIsVisible$ = this._visibility.pageIsVisible$.subscribe(e => {
        e ? this.setPause(false) : this.setPause(true);
      });
    }
  }

  /**
   * Destroy slider
   */
  private _destroySlider() {
    if (this._platform.isBrowser) {
      if (this._pageIsVisible$) {
        this._pageIsVisible$.unsubscribe();
      }
      if (this._slider) {this._slider.destroy();}
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'slideList': {
            setTimeout(() => {
              if (this._slider) {
                this._addSlideItemClass();
                this._initSlider();
                this._runSliderEmitters();
              }
            }, 200);
          }
        }
      }
    }
  }

  /**
   * Run manual detect changes
   */
  private _runDetectChanges() {
    try {
      if (this._slider || this.track) {
        this._cd.detectChanges();
        this.track.nativeElement.click();
      }
    } catch (error) {

    }
  }

  ngOnDestroy() {
    this._destroySlider();
  }
}
