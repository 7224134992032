import { Injectable } from '@angular/core';
import { ignoredHosts, methodsWithBiggerImage, methodsWithOwnImage, redirectRoutes, SS_LIB_CONFIG } from './ss-payment-config';
import { Observable, ReplaySubject } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { PaymentsLibType, Payment, Results, PaymentsMethod } from './ss-payment-types';
import { environment } from 'src/environments/environment';
import { PlatformService } from '../../services/platform.service';
import { WindowService } from '../../services/window.service';
import {fromPromise} from 'rxjs/internal/observable/innerFrom';

declare var PaymentsAPI: PaymentsLibType;

@Injectable({providedIn: 'root'})
export class SsPaymentsV2Service {

  /**
   * Is ready to use source
   */
  private _ready$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private _platform: PlatformService,
    private _window: WindowService
  ) {

  }

  /**
   * Load api library script
   */
  loadApiLibrary() {
    this._initApiLibraryConfig();
  }

  /**
   * Set Libary config & lazy load second libary part
   */
  private async _initApiLibraryConfig() {
    SS_LIB_CONFIG.serverUrl = this._resolveHost();
    PaymentsAPI?.config(SS_LIB_CONFIG)?.then(e => {
      if (PaymentsAPI._isLoaded) {
        this._ready$.next(true);
      }
    });
  }

  /**
   * Fetch payment methods list by currency & action
   * @param currency
   * @param action
   */
  public fetchMethods(currency: string, action: Payment.Action): Observable<PaymentsMethod[]> {
    return this._ready$.pipe(
      first(),
      switchMap(() => fromPromise(PaymentsAPI.getMethods({currency, paymentAction: action}))),
      map((list) => list.map((method: any) => {
        method.currency = currency;
        method.brand = method.id.split('_')[method.id.split('_').length - 1];
        method.provider = method.id.split('_')[0];
        return method;
      })),
      map((list: PaymentsMethod[]) => list.map(method => ({
        ...method,
        ourImg: this._resolveOurImage(method),
        isBiggerLogo: this._resolveBiggerImage(method)
      })))
    );
  }

  /**
   * Get fields for current method
   * @param id
   * @param currency
   * @param paymentAction
   * @param savedProfileId
   * @returns
   */
  public getCurrentMethodFields(id: any, currency: string, paymentAction: Payment.Action, savedProfileId: string = '') {
    return this._ready$.pipe(
      first(),
      switchMap(() => fromPromise(
        PaymentsAPI.getMethodFields({
          id,
          currency,
          paymentAction,
          savedProfileId
        })
      )),
      map(method => this._resolveMethodFields(method))
    );
  }

  /**
   * Resolve current method fields, add translate key
   * @param method
   * @returns
   */
  private _resolveMethodFields(method: Results.GetMethodFieldsResult) {
    console.log(method);

    if (method.amountField) {
      method.amountField.translationKey = 'labl.' + method.amountField.translationKey;
    }
    method.methodFields = method.methodFields.map(e => {
      e.translationKey = 'labl.' + e.translationKey;
      return e;
    });
    method.playerFields = method.playerFields.map((e: any) => {
      e.isProfile = true;
      e.fieldName = e.field;
      e.translationKey = 't.' + e?.field?.replace('_', '-');
      return e;
    }) as any[];
    return method;
  }

  /**
   * Deletes user's saved profile for the specific method
   * @param id
   * @param currency
   * @param paymentAction
   * @param savedProfileId
   * @returns
   */
  public deleteSavedAcc(id: any, currency: string, paymentAction: Payment.Action, savedProfileId: string) {
    return this._ready$.pipe(
      first(),
      switchMap(() => fromPromise(
        PaymentsAPI.deleteSavedProfile({
          id,
          currency,
          paymentAction,
          savedProfileId
        })
      ))
    );
  }

  /**
   * Responsible for processing the transaction, redirecting to the payment system, and opening a modal for additional data
   * @param id
   * @param currency
   * @param paymentAction
   * @param savedProfileId
   * @param methodFieldsData
   * @param playerFieldsData
   * @returns
   */
  public submitForm(
    id: string,
    currency: string,
    paymentAction: Payment.Action,
    methodFieldsData,
    savedProfileId: string = '',
    playerFieldsData = null) {
    SS_LIB_CONFIG['redirectRoutes'] = redirectRoutes(paymentAction);
    return this._ready$.pipe(
      first(),
      switchMap(() => fromPromise(PaymentsAPI.config(SS_LIB_CONFIG))),
      switchMap(() => fromPromise(PaymentsAPI.submit({
        id,
        currency,
        paymentAction,
        amountValue: methodFieldsData.amount,
        savedProfileId,
        methodFieldsData,
        playerFieldsData,
      } as any)))
    );
  }

  /**
   * Select method by default
   */
  public resolveDefaultMethod(methodList: PaymentsMethod[]): PaymentsMethod {
    const methodWithSavedAccount = methodList.find(method => method.savedProfiles.length);
    const firstAvailableMethod = methodList[0];
    return methodWithSavedAccount || firstAvailableMethod;
  }

  /**
   * Resolve payment host for mirrors
   * @returns
   */
  private _resolveHost() {
    return this._platform.isBrowser &&
    !ignoredHosts.some(item => item.includes(this._window.nativeWindow.location.host)) ?
      this._window.nativeWindow.location.origin?.replace('stage.', '')?.replace('https://www.', 'https://') :
      environment.ss_host;
  }

  /**
   * Returns payment method logo URL
   *
   * @param method
   * @private
   */
  private _resolveOurImage(method: PaymentsMethod): string {
    console.log(method.id);
    return methodsWithOwnImage.includes(method.id) ? `/assets/svg/payments/${method.id}.svg` : null;
  }


  /**
   * The function `_resolveBiggerImage` checks if a payment method has a bigger image based on its origin
   * brand.
   * @param {PaymentsMethod} method - PaymentsMethod
   * @returns The method returns a boolean value indicating whether the `originBrand` of the provided
   * `PaymentsMethod` object is included in the `methodsWithBiggerImage` array.
   */
  private   _resolveBiggerImage(method: PaymentsMethod): boolean {
    return methodsWithBiggerImage.includes(method.id);
  }

  public resetCache() {
    return fromPromise(PaymentsAPI.resetCache());
  }
}
