/**
 * Bonus stage list
 */
export enum BonusStage {
  HANDLE_BETS = 'handle_bets',
  LOST = 'lost',
  CANCELED = 'canceled',
  PLAYED = 'played',
  ISSUED = 'issued',
  EXPIRED = 'expired',
  WAGER_DONE = 'wager_done',
  ACTIVATED = 'activated',
  FINISHED = 'finished',
  SPINDER_FS = 'xmas_fs',
  SPINDER_LOOTBOX = 'lootbox_fs',
  SUMMER_FS = 'summer_fs',
  AVAILABLE = 'available'
}

export enum BonusCodeStatus {
  FAILED_TO_ACTIVATE = 'failed_to_activate',
  ALREADY_ACTIVATED = 'already_activated',
  SUCCESSFULLY_ACTIVATED = 'successfully_activated'
}

/**
 * Text labels for bonus stages
 */
export const BonusStageLabel = {
  [BonusStage.HANDLE_BETS]: 'Handle bets',
  [BonusStage.LOST]: 'Lost',
  [BonusStage.CANCELED]: 'Canceled',
  [BonusStage.PLAYED]: 'Played',
  [BonusStage.ISSUED]: 'Issued',
  [BonusStage.EXPIRED]: 'Expired',
  [BonusStage.WAGER_DONE]: 'Wager done',
  [BonusStage.ACTIVATED]: 'Activated',
  [BonusStage.FINISHED]: 'Finished',
  [BonusStage.SPINDER_FS]: 'xmas_fs',
  [BonusStage.SPINDER_LOOTBOX]: 'lootbox_fs',
  [BonusStage.AVAILABLE]: 'Available'
};

/**
 * Deposit bonus config contract
 */
export interface DepositBonusConfig {
  img: string;
  color: string;
  isDepositLootbox: boolean;
}

/**
 * Images for deposit bonuses
 */
export const DepositBonuses = new Map<string, DepositBonusConfig>([
  ['retention_lootbox_fs', {img: '/assets/img/bonuses/deposit/retention.png', color: '#9ef6d6', isDepositLootbox: false}],
  ['deposit_lootbox', {img: '/assets/img/bonuses/deposit/default.png', color: '#9ef6d6', isDepositLootbox: false}],
  ['deposit_bonus_lootbox', {img: '/assets/img/bonuses/deposit/default.png', color: '#9ef6d6', isDepositLootbox: true}],
  ['first_deposit_bonus', {img: '/assets/img/bonuses/deposit/1st_deposit.png', color: '#9ef6d6', isDepositLootbox: false}],
  ['default', {img: '/assets/img/bonuses/deposit/1st_deposit.png', color: '#9ef6d6', isDepositLootbox: false}],
]);

export const BonusStageLabelKeys = {
  [BonusStage.HANDLE_BETS]: 't.active',
  [BonusStage.CANCELED]: 't.canceled',
  [BonusStage.ISSUED]: 't.available',
  [BonusStage.EXPIRED]: 't.expired',
  [BonusStage.FINISHED]: 't.finished',
  [BonusStage.ACTIVATED]: 't.active',
  [BonusStage.WAGER_DONE]: 't.finished',
  [BonusStage.AVAILABLE]: 't.available'
};

export enum BONUS_TYPES {
  WELCOME = 'welcome',
  RELOAD = 'reload',
  DAILY = 'daily',
  CASH = 'money',
  FREESPINS = 'freespins',
  LOOTBOX = 'lootbox',
  DEPOSIT = 'deposit_bonus',
  NDFS = 'ndfs'
}

/**
 * There will be an animated lottie popup for these lootboxes
 */
export const LOOTBOXES_ANIMATION_GROUPS_KEY = {
  deposit_lootbox: {
    lottiePath: 'welcome-lootbox',
    scale: '1.4',
    activatedBgImage: '/assets/img/lootbox/explosion.png'
  },
  retention_lootbox_fs: {
    lottiePath: 'retention-lootbox',
    scale: '1.1',
    isRetentionLootbox: true
  } ,
  retention_lootbox_fs_manual: {
    lottiePath: 'retention-lootbox',
    scale: '1.1',
    isRetentionLootbox: true
  }
}
