import {Component, ElementRef, HostListener, OnInit} from '@angular/core';
import {NotificationCenterService} from '../notification-center.service';
import {WebSocketService} from '../../../services/web-socket.service';
import {UserService} from '../../../services/user/user.service';
import {InstallAppService} from '../../../services/install-app.service';
import { NotificationCenterComponent } from '../notification-center/notification-center.component';
import { NgClass, AsyncPipe } from '@angular/common';
import { ClickOutsideDirective } from '../../../shared/directives/click-outside.directive';

@Component({
    selector: 'app-notification-center-drop-down',
    templateUrl: './notification-center-drop-down.component.html',
    styleUrls: ['./notification-center-drop-down.component.scss'],
    standalone: true,
    imports: [ClickOutsideDirective, NgClass, NotificationCenterComponent, AsyncPipe]
})
export class NotificationCenterDropDownComponent implements OnInit {

  @HostListener('window:popstate') onPopstate() {
    this.notify.close();
  }

  constructor(
    public notify: NotificationCenterService,
    public ws: WebSocketService,
    private _el: ElementRef,
    private _user: UserService,
    private _installApp: InstallAppService
  ) {
  }

  ngOnInit() {
    this.notify.dropdownElement = this._el.nativeElement;
    if (this._installApp.showInstallAppNotificationMessage) {
      this.notify.installAppChannel$.next(true);
    }
  }

}
