import { Component, Input, OnInit } from '@angular/core';
import {UserService} from '../../../core/services/user/user.service';
import { BonusTypes } from '../offers.component';
import {EnvironmentService} from '../../../core/services/environment.service';
import {UserInfoService} from "../../../core/services/user/user-info.service";
import {ModalService} from '../../../core/modal-v2/modal.service';
import {LanguageService} from '../../../core/services/language/language.service';
import { Router, RouterLink } from '@angular/router';
import { SeoService } from '../../../core/services/seo.service';
import { TranslatePipe } from '../../../core/shared/translation/translate.pipe';
import { SafePipe } from '../../../core/shared/pipes/safe.pipe';
import { RouterLinkDirective } from '../../../core/shared/directives/router-link.directive';
import { NgClass } from '@angular/common';

export interface OfferPreviewInfo {
  title: string;
  description: string;
  image: string;
  color: string;
  topLabel?: string;
  secondDescription?: string;
  type?: BonusTypes;
  countFS?: string;
  hiddenButton?: boolean;
  taken?: boolean;
  missed?: boolean;
  HowItWorks?: string;
  Terms?: string;
  route?: string;
}

@Component({
    selector: 'app-offer-preview',
    templateUrl: './offer-preview.component.html',
    styleUrls: ['./offer-preview.component.scss'],
    standalone: true,
    imports: [NgClass, RouterLink, RouterLinkDirective, SafePipe, TranslatePipe]
})
export class OfferPreviewComponent implements OnInit {

  /**
   * Welcome bonus type
   */
  public welcomeBonus: BonusTypes = BonusTypes.WELCOME;

  /**
   * Reload bonus type
   */
  public reloadBonus: BonusTypes = BonusTypes.RELOAD;

  /**
   * Loyalty bonus type
   */
  public loyaltyBonus: BonusTypes = BonusTypes.LOYALTY;

  /**
   * Loyalty bonus type
   */
  public dailyBonus: BonusTypes = BonusTypes.DAILY;

  /**
   * Reload phone verification bonus
   */
  public reloadPhoneVerification: BonusTypes = BonusTypes.RELOAD_PHONE_VERIFICATION;

  /**
   * Blog bonus
   */
  public blogBonus: BonusTypes = BonusTypes.BLOG;

  @Input() offer: OfferPreviewInfo;

  @Input() activeLoyaltyIndex: number;

  @Input() loyaltyBonusIndex: number;

  @Input() buttonTitle: string;

  constructor(
    public user: UserService,
    public env: EnvironmentService,
    public userInfo: UserInfoService,
    public lang: LanguageService,
    public seo: SeoService,
    private _modals: ModalService,
    private _router: Router
  ) { }

  ngOnInit() {
  }

  get isCurrentLoyalty() {
    return this.offer.type === this.loyaltyBonus && this.loyaltyBonusIndex === this.activeLoyaltyIndex;
  }

  get isActiveLoyalty() {
    return this.offer.type === this.loyaltyBonus && this.loyaltyBonusIndex < this.activeLoyaltyIndex;
  }

  get isNotActiveLoyalty() {
    return this.offer.type === this.loyaltyBonus && this.loyaltyBonusIndex !== this.activeLoyaltyIndex;
  }

  public async openPhoneTermsInfoModal() {
    const component = await import('../../../core/modal-v2/components/lazy/phone-verification/phone-verification.component');
    await this._modals.openLazy(component?.PhoneVerificationComponent, {
      template: 'CLEAR',
      data: {
        Text: this.offer?.HowItWorks,
        Terms: this.offer?.Terms
      }
    })
  }


  public async onBonusClick(link?: string[]) {
    if (this.user.auth) {
      await this._router.navigate((link || [this.lang.current, 'deposit']),  !!link ? {queryParams: {scrollToPhoneVerification: !!link}} :{});
    } else {
      await this.user.authUser();
    }
  }
}
