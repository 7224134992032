import { BadgeService, WATCHED_FOR_OPEN } from '../../core/services/badge.service';
import {Component, forwardRef, OnDestroy, OnInit} from '@angular/core';
import { SideNavComponent } from '../../core/shared/layout/side-nav/side-nav.component';
import { HeaderComponent } from '../../core/shared/layout/header/header.component';
import { OffersService } from './offers.service';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { SeoService } from '../../core/services/seo.service';
import {catchError, filter, first, map, share, shareReplay, switchMap, tap} from 'rxjs/operators';
import { StaticPageService } from '../static-page/static-page.service';
import { UserService } from '../../core/services/user/user.service';
import { GroupsService } from '../../core/services/groups.service';
import { EnvironmentService } from '../../core/services/environment.service';
import { UserBonusesService } from '../../core/services/user/user-bonuses.service';
import { LootboxService } from '../../core/services/lootbox/lootbox.service';
import { BonusStage } from '../../core/services/user/data/user-bonuses.data';
import { ToastMessageService } from '../../core/modules/toast-message/toast-message.service';
import { untilDestroyed } from 'ngx-unificator/rxjs';
import { UntilDestroy } from 'ngx-unificator/decorator';
import { LanguageService } from '../../core/services/language/language.service';
import { Router, RouterLink } from '@angular/router';
import { EventType } from '../promo/base-promo';
import { ModalService } from '../../core/modal-v2/modal.service';
import { CookieService } from 'ngx-unificator/services';
import {UserPhoneService} from '../../core/services/user/user-phone.service';
import {GamesService} from '../../core/services/games/games.service';
import {BlogService} from '../blog/blog.service';
import {BlogCategory} from '../blog/blog-articles-list/blog-articles-list.component';
import {TranslationService} from '../../core/shared/translation/translation.service';
import { BONUSES_LIST_PLACE, BonusesSliderComponent } from '../../core/shared/components/bonuses/bonuses-slider/bonuses-slider.component';
import { TranslatePipe } from '../../core/shared/translation/translate.pipe';
import { SafePipe } from '../../core/shared/pipes/safe.pipe';
import { SkeletonComponent } from '../../core/shared/components/skeleton/skeleton.component';
import { PreloaderComponent } from '../../core/shared/components/preloader/preloader.component';
import { BottomTextComponent } from '../../core/shared/layout/bottom-text/bottom-text.component';
import { FooterComponent } from '../../core/shared/layout/footer/footer.component';
import { BottomNavigationComponent } from '../../core/shared/layout/bottom-navigation/bottom-navigation.component';
import { AccordionDirective } from '../../core/shared/directives/accordion.directive';
import { SocialOfferComponent } from './social-offer/social-offer.component';
import { OfferPreviewComponent } from './offer-preview/offer-preview.component';
import { RouterLinkDirective } from '../../core/shared/directives/router-link.directive';
import { NgClass, AsyncPipe } from '@angular/common';

export enum BonusTypes {
  WELCOME = 'welcome',
  RELOAD = 'reload',
  LOYALTY = 'loyalty',
  DAILY = 'daily',
  RELOAD_PHONE_VERIFICATION = 'reload-phone-verification',
  BLOG = 'blog'
}

export const CLAIMED_WELCOME_BONUSES_GROUP = 'ID62';
export const CLAIMED_WELCOME_4_SETUP = 'ID610';

export const USER_HAVE_ONE_DEPOSIT_GROUP = 'ID466';

const PHONE_VERIFICATION_SLUG = 'phone-verification';
export const USER_RECEIVED_PHONE_BONUS = 'ID467';

@UntilDestroy()
@Component({
    selector: 'app-offers',
    templateUrl: './offers.component.html',
    styleUrls: ['./offers.component.scss'],
    standalone: true,
    imports: [HeaderComponent, forwardRef(() => SideNavComponent), NgClass, RouterLink, RouterLinkDirective, BonusesSliderComponent, OfferPreviewComponent, SocialOfferComponent, AccordionDirective, BottomNavigationComponent, FooterComponent, BottomTextComponent, PreloaderComponent, SkeletonComponent, AsyncPipe, SafePipe, TranslatePipe]
})
export class OffersComponent implements OnInit, OnDestroy {

  /**
   * Promo list observable
   */
  public readonly list$: ReplaySubject<any> = this._offers.list();

  /**
   * SEO Text from CMS
   */
  public offersText$: ReplaySubject<any> = this._page.item({
    slug: 'bonuses'
  }).pipe(
    tap((data) => {
      if (data) {
        this._seo.setMetaTags(data[0] || {});
      }
    }),
    shareReplay(1)
  ) as ReplaySubject<any>;

  /**
   * List of initialized sliders
   */
  public readonly sliders: any = {};

  /**
   * Welcome bonus type
   */
  public welcomeBonus: BonusTypes = BonusTypes.WELCOME;

  /**
   * Reload bonus type
   */
  public reloadBonus: BonusTypes = BonusTypes.RELOAD;

  /**
   * Loyalty bonus type
   */
  public loyaltyBonus: BonusTypes = BonusTypes.LOYALTY;

  /**
   * Deposit bonus list from SS
   * @private
   */
  private _depositBonusList$ = this.user.auth ? this._bonuses.depositBonusList() : of([]);

  /**
   * Cms bonus list
   * @private
   */
  public dailyRewardBonus$ = this._offers.list({
    category_slug: 'description-bonus'
  }).pipe(
    filter(list => !!list),
    map(list => list.map(bonus => ({
      ...bonus,
      type: BonusTypes.DAILY
    }))),
  );

  /**
   * Welcome bonuses list observable
   */
  public readonly welcomeBonuses$: Observable<any> = combineLatest([this._depositBonusList$, this._bonuses.cmsBonusList$])
    .pipe(
      tap(([bonusListSS, bonusListCMS]) => {
        if (bonusListSS.length) {
          this.currentWelcomeIndex = this._bonuses.findActiveBonusCMSIndex(bonusListCMS, bonusListSS);
          this.currentWelcomeIndex = this.currentWelcomeIndex < 0 ? 0 : this.currentWelcomeIndex;
        }
      }),
      map(([bonusListSS, bonusListCMS]) => bonusListCMS)
    );

  /**
   * Reload bonuses list observable
   */
  public readonly reloadBonuses$: Observable<any> = (this.user.auth ? this._phone.getPhoneList() : of(true)).pipe(
    switchMap(() => this._offers.list({
      category_slug: 'reload-bonuses'
    })),
    tap(() => {
      this._checkDifferenceMoreTwoDays();
      this._isUserHaveDepositPhoneGroup = this._groups.isExistGroup(USER_HAVE_ONE_DEPOSIT_GROUP);
      this._isUserReceivedPhoneBonus = this._groups.isExistGroup(USER_RECEIVED_PHONE_BONUS);
    }),
    map(list => list?.filter(b => (!this._phone.verified && !this._isUserReceivedPhoneBonus && this._isUserHaveDepositPhoneGroup && this.user.auth && this._isMoreTwoDaysVerificationPhone) ? true : b?.slug !== PHONE_VERIFICATION_SLUG)),
    map(list => {
      return list?.map(bonus => {
        return {
          title: bonus.title,
          description: bonus.description,
          image: bonus.image,
          color: bonus.color,
          type: bonus?.slug === PHONE_VERIFICATION_SLUG ? BonusTypes.RELOAD_PHONE_VERIFICATION : this.reloadBonus,
          countFS: bonus.countFS,
          slug: bonus.slug,
          HowItWorks: bonus?.HowItWorks,
          Terms: bonus?.Terms
        };
      }).sort((a, b) => b?.slug === PHONE_VERIFICATION_SLUG ? 1 : -1);
    }),
    share()
  );

  /**
   * Loyalty bonuses list observable
   */
  public readonly loyaltyBonuses$: Observable<any> = this._offers.list({
    category_slug: 'loyalty-bonuses'
  }).pipe(
    filter(list => !!list),
    tap(list => {
      if (this.user.auth) {
        const activeLoyaltyIndex = list.findIndex(l => l.slug.replace('-', '') === this._groups.loyaltyGroup);
        this.loyaltyActiveIndex = activeLoyaltyIndex === 0 || activeLoyaltyIndex ? activeLoyaltyIndex : null;
      } else {
        this.loyaltyActiveIndex = 0;
      }
    }),
    map(list => {
      return list.map(bonus => {
        return {
          title: bonus.title,
          description: bonus.description,
          image: bonus.image,
          color: bonus.color,
          topLabel: bonus.topLabel,
          type: this.loyaltyBonus,
          countFS: bonus.countFS
        };
      });
    })
  );

  public blogBonuses$ = this._blog.list({
    type: BlogCategory.PROMOTIONS,
  }).pipe(
    map((data) => (data || [])?.filter(e => e?.ShowOnBonusPage)),
    map((data) => {
      if (!this.user.auth) {
        return data?.filter((e) => !e?.OnlyAuth);
      }
      return data;
    })
  );

  public allReloadBonuses$ = combineLatest([
    this.reloadBonuses$, this.blogBonuses$
  ]).pipe(
    filter(([reload, blog]) => !!reload && !!blog),
    map(data => {
      return [...data[1]?.map(b => ({
        image: b?.BonusImage,
        title: b?.BonusTitle,
        description: b?.BonusDesc,
        type: BonusTypes.BLOG,
        route: b?.BonusButtonRoute,
      })), ...data[0],
      ]
    })
  )
  /**
   * Special bonuses from SS (bonuses, deposit bonuses, free spins, lootboxes)
   */
  public specialBonuses$: Observable<any>;

  /**
   * Social promo
   */
  public promo$ = this._offers.item({ slug: 'telegram-viber-promo' });

  /**
   * Loyalty active index
   */
  public loyaltyActiveIndex: number;

  /**
   * Current welcome index bonus
   */
  public currentWelcomeIndex = 0;

  /**
   * Is welcome bonuses claimed
   */
  public isWelcomeBonusesClaimed: boolean;

  private _isMoreTwoDaysVerificationPhone = false;
  private _isUserHaveDepositPhoneGroup = false;
  private _isUserReceivedPhoneBonus = false;

  public bonusesPlace = BONUSES_LIST_PLACE;

  constructor(
    private _offers: OffersService,
    private _seo: SeoService,
    private _badge: BadgeService,
    private _page: StaticPageService,
    private _groups: GroupsService,
    private _bonuses: UserBonusesService,
    private _lootbox: LootboxService,
    private _modal: ModalService,
    private _toastMessage: ToastMessageService,
    private _lang: LanguageService,
    private _router: Router,
    private _cookie: CookieService,
    private _games: GamesService,
    private _phone: UserPhoneService,
    private _blog: BlogService,
    private _translate: TranslationService,
    public user: UserService,
    public env: EnvironmentService,
    public seo: SeoService
  ) { }

  ngOnInit() {
    this._seo.setMetaTags({ MetaTitle: 'Wild Fortune | Offers' });
    this._badge.openListEntity(WATCHED_FOR_OPEN.OFFERS);

    this.user.auth$.pipe(
      untilDestroyed(this),
      filter(auth => !!auth),
      tap(() => {
        this.isWelcomeBonusesClaimed = this._groups.isExistGroup(CLAIMED_WELCOME_BONUSES_GROUP);
        this._lootbox.loadUserLoobox().subscribe();
        this._updateSSBonusList();
      })
    ).subscribe();

    this._lang.langChange$.pipe(
      untilDestroyed(this),
      tap(() => {
        this._updateSSBonusList();
      })
    ).subscribe();

    this.allReloadBonuses$.subscribe();
  }

  ngOnDestroy() {
  }

  get Stage() {
    return BonusStage;
  }

  get EventType() {
    return EventType;
  }

  private _updateSSBonusList() {
    this.specialBonuses$ = combineLatest([
      this._bonuses.freeSpinsList(),
      this._bonuses.bonusList(),
      this._lootbox.lootIssuedboxList$
    ]).pipe(
      map(([fs, bonuses, lootbox]) => fs.concat(bonuses).concat(lootbox)),
      map(list => {
        return list.map(b => {
          const gamesInfo = b?.games_info?.length === 1 ? b?.games_info : b?.games_info?.slice(0, 2);
          return {
            ...b,
            games$: gamesInfo ? this._games.list({'external_id[]': gamesInfo?.map(g => g.identifier)}).pipe(
              map(data => data?.gameList),
              share()
            ) : of(gamesInfo)
          }
        })
      }),
      map(list => list.filter(bonus => bonus.active !== false && bonus.stage !== this.Stage.EXPIRED && bonus.stage !== this.Stage.FINISHED))
    );
  }

  /**
   * Check is bonus FS
   *
   * @private
   */
  private _isFSBonus(bonus) {
    return bonus.activation_path && bonus.activation_path.includes('freespins');
  }

  /**
   * Cancel provided bonus
   *
   * @param bonus
   */
  cancel(bonus: any) {
    const modal = this._modal.open('BONUS_CANCEL_CONFIRM');
    let path;
    if (this._isFSBonus(bonus)) {
      path = this._bonuses.cancelFreeSpins(bonus.id);
    } else if (this._bonuses.isLootbox(bonus)) {
      path = this._lootbox.cancelLootbox(bonus.id);
    } else {
      path = this._bonuses.cancelBonus(bonus.id);
    }

    modal.onResult().pipe(
      first(),
      filter(confirm => !!confirm),
      switchMap(() => {
        bonus.loading = true;
        return path;
      }),
      tap(() => {
        this._toastMessage.success('t.bonus-canceled');
        this._updateSSBonusList();
      }),
      catchError(error => {
        this._toastMessage.error('t.error');
        return of(error);
      })
    ).subscribe(() => {
      bonus.loading = false;
    });
  }

  /**
   * Activate provided bonus
   *
   * @param bonus
   */
  activate(bonus: any) {
    bonus.loading = true;
    let path;
    if (this._isFSBonus(bonus)) {
      path = this._bonuses.activateFreeSpins(bonus.id);
    } else {
      path = this._bonuses.activateBonus(bonus.id);
    }

    path.pipe(
      tap(() => {
        bonus.pending_activation = true;
        this._toastMessage.success('t.few-moments');
      }),
      catchError(error => {
        this._toastMessage.error('t.error');
        return of(error);
      })
    ).subscribe(() => {
      bonus.loading = false;
    });
  }

  public onBonusClick() {
    if (this.user.auth) {
      this._router.navigateByUrl('/deposit').then();
    } else {
      this.user.authUser().then();
    }
  }

  private _checkDifferenceMoreTwoDays() {
    if (!this.user.info.created_at) {
      this._isMoreTwoDaysVerificationPhone = true;
    } else {
      const today = new Date();
      // ios problem handling
      const dateString = this.user.info.created_at.replace(" ", "T").replace('UTC', '').trim() + 'Z';
      const dateRegister = new Date(dateString);
      const millisecondsDiff = today.getTime() - dateRegister.getTime();
      this._isMoreTwoDaysVerificationPhone = Math.round(
        millisecondsDiff / (24 * 60 * 60 * 60)
      ) >= 2;
    }
  }
}
