import { Component, OnDestroy, OnInit } from '@angular/core';
import { delay, filter, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { untilDestroyed } from 'ngx-unificator/rxjs';
import { UntilDestroy } from 'ngx-unificator/decorator';
import {ModalContent, ModalRef} from '../../../modal-ref';
import {UpdateProfileFormController} from '../../../../helpers/user/update-profile-form-controller';
import {UserFieldType} from '../../../../helpers/user/user-fields.data';
import { IMoveFieldData, MoveNextFieldDirective } from '../../../../shared/directives/move-next-field.directive';
import {GoogleTagManagerService} from '../../../../services/google-tag-manager.service';
import {UserService} from '../../../../services/user/user.service';
import {UserInfoService} from '../../../../services/user/user-info.service';
import {EnvironmentService} from '../../../../services/environment.service';
import { ScrollService } from '../../../../services/scroll.service';
import { DeviceDetectorService } from 'ngx-unificator/services';
import { ZendeskChatService } from '../../../../services/zendesk/zendesk-chat.service';
import {PlatformService} from '../../../../services/platform.service';
import { TranslatePipe } from '../../../../shared/translation/translate.pipe';
import { PreloaderComponent } from '../../../../shared/components/preloader/preloader.component';
import { NumberOnlyDirective } from '../../../../shared/directives/only-number.directive';
import { DobMaskDirective } from '../../../../shared/directives/dob-mask.directive';
import { PhoneFormComponent } from '../../../../standalone/phone-form/phone-form.component';
import { NgxMaskDirective } from 'ngx-mask';
import { FormInputComponent } from '../../../../modules/form-controls/form-input/form-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgIf, NgFor, NgSwitch, NgSwitchCase, NgClass, AsyncPipe } from '@angular/common';
import {SelectModule} from '../../../../modules/select/select.module';

@UntilDestroy()
@Component({
    selector: 'app-auth-fields-missed-update',
    templateUrl: './auth-fields-missed-update.component.html',
    styleUrls: ['./auth-fields-missed-update.component.scss'],
    standalone: true,
    imports: [NgIf, ReactiveFormsModule, MoveNextFieldDirective, NgFor, NgSwitch, NgSwitchCase, FormInputComponent, NgClass, NgxMaskDirective, PhoneFormComponent, DobMaskDirective, NumberOnlyDirective, SelectModule, PreloaderComponent, AsyncPipe, TranslatePipe]
})
export class AuthFieldsMissedUpdateComponent implements OnInit, OnDestroy, ModalContent {

  /**
   * Current fields by step
   */
  fields: string[] = [];

  /**
   * Edit profile form controller
   */
  public controller: UpdateProfileFormController = new UpdateProfileFormController('edition');

  /**
   * Link to modal window
   */
  public modal: ModalRef;

  public readonly FieldType = UserFieldType;

  /**
   * Use for MoveNextFieldDirective
   */
  public controlList: IMoveFieldData[] = [
    { name: 'gender', maxLength: 1 },
    { name: 'city', maxLength: 1, isSelect: true },
    { name: 'address', maxLength: 1, isSelect: true },
  ];

  constructor(
    private _router: Router,
    private _gtm: GoogleTagManagerService,
    private _user: UserService,
    private _scroll: ScrollService,
    private _device: DeviceDetectorService,
    private _zendesk: ZendeskChatService,
    public userInfo: UserInfoService,
    private _platfrom: PlatformService,
    public env: EnvironmentService
  ) {

  }

  ngOnInit() {
    this._handleEvents();

    if (this._device.isMobile()) {
      this._scroll.blockScroll();
    }
  }

  private _handleEvents() {
    /**
     * Form result handler
     */
    this.controller.updated$.pipe(
      untilDestroyed(this),
      filter(updated => !!updated),
      tap(() => {



        if (this.modal.options.data && this.modal.options.data.url) {
          this._router.navigateByUrl(this.modal.options.data.url).then();
        }


        setTimeout(() => this.modal.close(true), 100);
      }),
      tap(() => {
        // Bigger timeout needed to auth user when he fill missed fields, because
        // User would not exist in replica
        setTimeout(() => {
          this._zendesk.setUserData();
        }, 5000)
      })
    ).subscribe();
  }

  ngOnDestroy() {
    if (this._device.isMobile()) {
      this._scroll.UnblockScroll();
    }
  }
}
