import { Component, Input, OnInit } from '@angular/core';
import { RouterLinkDirective } from '../../../core/shared/directives/router-link.directive';
import { RouterLink } from '@angular/router';
import { NgClass, DatePipe } from '@angular/common';

@Component({
    selector: 'app-blog-article-preview',
    templateUrl: './blog-article-preview.component.html',
    styleUrls: ['./blog-article-preview.component.scss'],
    standalone: true,
    imports: [NgClass, RouterLink, RouterLinkDirective, DatePipe]
})
export class BlogArticlePreviewComponent implements OnInit {

  /**
   * Article to display
   */
  @Input() article: any;

  /**
   * Is current component uses as preloader
   */
  public preloader: boolean;

  constructor() { }

  ngOnInit() {
    if (!this.article) {
      this.preloader = true;
    }
  }

}
