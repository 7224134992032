import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-skeleton',
    templateUrl: './skeleton.component.html',
    styleUrls: ['./skeleton.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgClass]
})
export class SkeletonComponent implements OnInit, OnChanges {

  @Input() count = 0;

  @Input() type: 'circle' | 'line' | 'block' = 'line';

  @Input() animation: 'progress' | 'pulse' = 'progress';

  public items: any[];

  constructor() {}

  ngOnInit() {
    this.validateInputValues();
  }

  private validateInputValues() {
    this.items = [...Array(this.count)];
  }

  ngOnChanges(changes: SimpleChanges) {
    this.validateInputValues();
  }
}
