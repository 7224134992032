  import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TimeService } from '../../../../core/services/time.service';
import { BaseTimerComponent } from '../../../../core/helpers/base-timer.component';
import { TranslatePipe } from '../../../../core/shared/translation/translate.pipe';


@Component({
    selector: 'app-summer-timer',
    templateUrl: './summer-timer.component.html',
    styleUrls: ['./summer-timer.component.scss'],
    standalone: true,
    imports: [TranslatePipe]
})
export class SummerTimerComponent extends BaseTimerComponent implements OnInit {

  constructor(
    private _time: TimeService
  ) {
    super();
  }

  ngOnInit(): void {
    this.resolveTimer();
  }

}
