<div class="track keen-slider" [ngClass]="class" #track
  (mouseover)="setPause(true)"
  (mouseout)="setPause(false)"
  (click)="$event.stopPropagation()"
  >
  <ng-content></ng-content>
</div>

<ng-template #pagination>
  <div class="pagination-container">
    @if (slider) {
      <div class="pagination">
        @for (slide of slideList; track slide; let i = $index) {
          <span class="pagination__item" (click)="slider?.moveToSlide(i)"
          [ngClass]="{active: i === currentSlide}"></span>
        }
      </div>
    }
  </div>
</ng-template>
