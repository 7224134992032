@if (bonuses$ | async; as bonuses) {
  <div class="bonuses">
    @switch (place) {
      @case (BONUSES_LIST_PLACE.ACCOUNT) {
        @if (bonuses && bonuses.length) {
          <div class="bonuses__title">
            <h2>{{ 't.bonuses' | translate }}</h2>
            <i class="icon-info-one" (click)="openClaimBonusesModal()"></i>
          </div>
        }
      }
      @case (BONUSES_LIST_PLACE.BONUSES) {
        @if (bonuses && bonuses.length) {
          <div class="bonuses__title bonuses__title--small">
            <h2>{{ 't.special-bonuses' | translate }}</h2>
          </div>
        }
      }
    }
    <div class="bonuses__wrapper">
      @if (bonuses?.length) {
        @if (isDesktop) {
          @if (bonuses.length > 3 && place === BONUSES_LIST_PLACE.BONUSES || bonuses?.length > 2 && place === BONUSES_LIST_PLACE.ACCOUNT) {
            <div class="bonuses__slider">
              <app-slider
                #slider
                [intersectionObserver]="false"
                [slideList]="bonuses"
                [config]="sliderConfig"
                >
                @for (bonus of bonuses; track bonus) {
                  @if (bonus.stage !== Stage.EXPIRED && bonus.stage !== Stage.FINISHED) {
                    <app-bonus-card
                      (onCancel)="cancel($event)"
                      (onActivate)="activate($event)"
                      [bonus]="bonus"
                      [type]="bonus.type || bonus?.bonus?.type"
                    ></app-bonus-card>
                  }
                }
              </app-slider>
              <div class="pagination" [ngClass]="{bonuses: place === BONUSES_LIST_PLACE.BONUSES}">
                @if (slider) {
                  <ng-container *ngTemplateOutlet="slider?.paginationElement"></ng-container>
                }
              </div>
            </div>
            @if (displayBonusCode) {
              <div class="bonuses__bonus-code">
                <app-no-deposit-bonus></app-no-deposit-bonus>
              </div>
            }
          } @else {
            <div class="bonuses__list" [ngClass]="{account: place === BONUSES_LIST_PLACE.ACCOUNT}">
              @for (bonus of bonuses; track bonus) {
                @if (bonus.stage !== Stage.EXPIRED && bonus.stage !== Stage.FINISHED) {
                  <app-bonus-card
                    (onCancel)="cancel($event)"
                    (onActivate)="activate($event)"
                    [bonus]="bonus"
                    [type]="bonus.type || bonus?.bonus?.type"
                  ></app-bonus-card>
                }
              }
              @if (displayBonusCode) {
                <div class="bonuses__bonus-code" [ngClass]="{'width100': bonuses?.length === 3}" [ngStyle]="{'grid-column': bonuses?.length === 1 ? 'span 2' : ''}">
                  <app-no-deposit-bonus
                    [isSmallTwoCardDesktopMode]="bonuses?.length === 2"
                    [isSmallOneCardDesktopMode]="bonuses?.length === 1"
                  ></app-no-deposit-bonus>
                </div>
              }
            </div>
          }
        } @else {
          @if (bonuses?.length) {
            @switch (true) {
              @case (bonuses?.length >= 3) {
                <div class="bonuses__slider">
                  <app-slider
                    #slider
                    [intersectionObserver]="false"
                    [slideList]="bonuses"
                    [config]="sliderConfig"
                    >
                    @for (bonus of bonuses; track bonus) {
                      @if (bonus.stage !== Stage.EXPIRED && bonus.stage !== Stage.FINISHED) {
                        <app-bonus-card
                          (onCancel)="cancel($event)"
                          (onActivate)="activate($event)"
                          [bonus]="bonus"
                          [type]="bonus.type || bonus?.bonus?.type"
                        ></app-bonus-card>
                      }
                    }
                  </app-slider>
                  <div class="pagination" [ngClass]="{bonuses: place === BONUSES_LIST_PLACE.BONUSES}">
                    @if (slider) {
                      <ng-container *ngTemplateOutlet="slider?.paginationElement"></ng-container>
                    }
                  </div>
                </div>
              }
              @default {
                <div class="bonuses__list" [ngClass]="{center: bonuses?.length === 1}">
                  @for (bonus of bonuses; track bonus) {
                    @if (bonus.stage !== Stage.EXPIRED && bonus.stage !== Stage.FINISHED) {
                      <app-bonus-card
                        (onCancel)="cancel($event)"
                        (onActivate)="activate($event)"
                        [bonus]="bonus"
                        [type]="bonus.type || bonus?.bonus?.type"
                      ></app-bonus-card>
                    }
                  }
                </div>
              }
            }
            <div class="container">
              @if (displayBonusCode) {
                <div class="bonuses__bonus-code">
                  <app-no-deposit-bonus></app-no-deposit-bonus>
                </div>
              }
            </div>
          }
        }
      }
      @if (displayBonusCode && !!!bonuses?.length) {
        <div class="bonuses__bonus-code">
          <app-no-deposit-bonus></app-no-deposit-bonus>
        </div>
      }
    </div>
  </div>
} @else {
  <app-preloader></app-preloader>
}

