import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Observable, concat, defer, of, fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VisibilityService {

  private _pageVisible$: Observable<boolean> = concat(
    defer(() => of(!this._document.hidden)),
    fromEvent(this._document, 'visibilitychange')
      .pipe(
        map(e => !this._document.hidden)
      )
  );

  constructor(
    @Inject(DOCUMENT) private _document: Document,
  ) {

  }

  public get pageIsVisible$() {
    return this._pageVisible$;
  }

}
