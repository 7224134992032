<app-header></app-header>

<app-side-nav></app-side-nav>

<div class="top-container">
  @if (!user.auth || !isWelcomeBonusesClaimed) {
    @if (welcomeBonuses$ | async; as welcomeBonuses) {
      @if (currentWelcomeIndex === 0 || currentWelcomeIndex) {
        <div
          class="container"
          >
          @if (welcomeBonuses.length) {
            <div class="welcome">
              <div class="welcome__list">
                @for (bonus of welcomeBonuses; track bonus; let i = $index) {
                  <div
                    class="welcome__item"
              [ngClass]="{
                active: i === currentWelcomeIndex,
                taken: i < currentWelcomeIndex
              }"
                    >
                    <div class="welcome__item_border"></div>
                    <div
                      class="welcome__item_sequence"
                      [innerHTML]="bonus?.topLabel | safe: 'html'"
                    ></div>
                    <div
                      class="welcome__item_title"
                      [innerHTML]="bonus?.title | safe: 'html'"
                    ></div>
                    <div class="welcome__item_toggle">
                      <div
                        class="welcome__item_description"
                        [innerHTML]="bonus?.description | safe: 'html'"
                      ></div>
                      <a
                        class="btn btn--filled-primary big"
                        [routerLink]="user.auth ? '/deposit' : '/register'"
                        >
                        {{ "t.get-bonus" | translate }}
                      </a>
                      @if (bonus?.secondDescription) {
                        <div
                          class="welcome__item_second-description"
                          >
                          @if (bonus?.countFS) {
                            <div class="count-fs">
                              <span
                                >{{ bonus?.countFS || "20"
                                }}{{ "t.fs" | translate }}</span
                                >
                                <span>{{ "t.day" | translate }}</span>
                              </div>
                            }
                            {{ bonus?.secondDescription }}
                          </div>
                        }
                      </div>
                    </div>
                  }
                </div>
                <div class="welcome__image">
                  <img
                    [src]="welcomeBonuses[currentWelcomeIndex]?.image"
                    [alt]="seo.metaTitle"
                    />
                  </div>
                </div>
              }
            </div>
          }
        } @else {
          <div class="top-container">
            <div class="container welcome-preloader">
              <div class="row">
                <div class="left">
                  <div class="welcome__item">
                    <div
                      class="welcome__item_border"
                      [ngClass]="{ 'active-preloader': true }"
                    ></div>
                    <div class="item first_item">
                      <div class="item__title">
                        <app-skeleton
                          [count]="1"
                          [animation]="'progress'"
                          [type]="'block'"
                        ></app-skeleton>
                      </div>
                      <div class="item__title-main">
                        <app-skeleton
                          [count]="1"
                          [animation]="'progress'"
                          [type]="'block'"
                        ></app-skeleton>
                      </div>
                      <div class="item__title-main">
                        <app-skeleton
                          [count]="1"
                          [animation]="'progress'"
                          [type]="'block'"
                        ></app-skeleton>
                      </div>
                      <div class="item__subtitle">
                        <app-skeleton
                          [count]="1"
                          [animation]="'progress'"
                          [type]="'block'"
                        ></app-skeleton>
                      </div>
                      <div class="item__button">
                        <app-skeleton
                          [count]="1"
                          [animation]="'progress'"
                          [type]="'block'"
                        ></app-skeleton>
                      </div>
                      <div class="item__row">
                        <div class="row__days">
                          <app-skeleton
                            [count]="1"
                            [animation]="'progress'"
                            [type]="'circle'"
                          ></app-skeleton>
                        </div>
                        <div class="row__text">
                          <app-skeleton
                            [count]="1"
                            [animation]="'progress'"
                            [type]="'block'"
                          ></app-skeleton>
                          <app-skeleton
                            [count]="1"
                            [animation]="'progress'"
                            [type]="'block'"
                          ></app-skeleton>
                        </div>
                      </div>
                    </div>
                  </div>
                  @for (i of [0, 1]; track i) {
                    <div class="welcome__item">
                      <div class="welcome__item_border"></div>
                      <div class="item first_item">
                        <div class="item__row">
                          <div class="item__title">
                            <app-skeleton
                              [count]="1"
                              [animation]="'progress'"
                              [type]="'block'"
                            ></app-skeleton>
                          </div>
                          <div class="item__title">
                            <app-skeleton
                              [count]="1"
                              [animation]="'progress'"
                              [type]="'block'"
                            ></app-skeleton>
                          </div>
                        </div>
                        <div class="item__title-main">
                          <app-skeleton
                            [count]="1"
                            [animation]="'progress'"
                            [type]="'block'"
                          ></app-skeleton>
                        </div>
                      </div>
                    </div>
                  }
                </div>
                <div class="right">
                  <div class="right__image">
                    <app-skeleton
                      [count]="1"
                      [animation]="'progress'"
                      [type]="'circle'"
                    ></app-skeleton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      }

      @if (user.auth) {
        <div class="container container--bonuses">
          <app-bonuses-slider [place]="bonusesPlace.BONUSES"></app-bonuses-slider>
        </div>
      }

      @if (dailyRewardBonus$ | async; as dailyBonus) {
        <div class="container daily">
          <div
            class="bonus-list bonus-list--reload"
            >
            @if (dailyBonus.length) {
              <div class="bonus-list__title">
                {{ "t.daily-reward-bonus" | translate }}
              </div>
              <div class="bonus-list__wrapper bonus-list__wrapper--reload">
                @for (bonus of dailyBonus; track bonus; let i = $index) {
                  <app-offer-preview [offer]="bonus" [buttonTitle]="'t.lets-go'"></app-offer-preview>
                }
                <div class="bonus-steps">
                  <div class="bonus-steps_title">{{ 't.how-you-can-get' | translate }}</div>
                  <div class="bonus-steps_list">
                    <div><span>1</span> <span>{{ 't.daily-first-step' | translate }}</span></div>
                    <div><span>2</span> <span>{{ 't.daily-second-step' | translate }}</span></div>
                    <div><span>3</span> <span>{{ 't.daily-third-step' | translate }}</span></div>
                  </div>
                  <a class="btn btn--filled-primary big" (click)="onBonusClick()" [routerLink]="user.auth ? '/deposit' : '/register'">
                    {{ 't.get-bonus' | translate }}
                  </a>
                </div>
              </div>
            }
          </div>
        </div>
      }
    </div>

    <div class="bottom-container">
      <div class="container">
        @if (allReloadBonuses$ | async; as reloadBonuses) {
          <div
            class="bonus-list bonus-list--reload"
            >
            @if (reloadBonuses.length) {
              <div class="bonus-list__title">
                {{ "t.bonuses-reload" | translate }}
              </div>
              <div class="bonus-list__wrapper bonus-list__wrapper--reload">
                @for (bonus of reloadBonuses; track bonus; let i = $index) {
                  <app-offer-preview [offer]="bonus" [buttonTitle]="bonus?.BonusButtonName"></app-offer-preview>
                }
              </div>
            }
          </div>
        }
        @if (loyaltyBonuses$ | async; as loyaltyBonuses) {
          <div
            class="bonus-list bonus-list--loyalty"
            >
            @if (loyaltyBonuses.length) {
              <div class="bonus-list__title">
                {{ "t.bonuses-loyalty" | translate }}
              </div>
              <div class="bonus-list__wrapper">
                @for (bonus of loyaltyBonuses; track bonus; let i = $index) {
                  <app-offer-preview
                    [offer]="bonus"
                    [loyaltyBonusIndex]="i"
                    [activeLoyaltyIndex]="loyaltyActiveIndex"
                    >
                  </app-offer-preview>
                  @if (i === 3 && promo$ | async; as promo) {
                    @if (promo && promo[0] && promo[0]?.Title) {
                      <div class="social">
                        <app-social-offer [offer]="promo"></app-social-offer>
                      </div>
                    }
                  }
                }
              </div>
            }
          </div>
        }
      </div>

      @if (offersText$ | async; as text) {
        @if (text[0]?.Terms) {
          <div class="container">
            <div class="terms">
              <div class="terms">
                <div class="accordion">
                  <div class="accordion__header" Accordion>
                    <div>{{ "t.bonus-terms" | translate }}</div>
                    <i class="accordion__icon icon-pointer-down"></i>
                  </div>
                  <div class="accordion__content">
                    <div [innerHTML]="text[0]?.Terms | safe: 'html'"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      }

    </div>

    <app-bottom-navigation></app-bottom-navigation>

    <app-footer>
      @if (offersText$ | async; as text) {
        <app-bottom-text
          [text]="text[0]?.SeoContent"
        ></app-bottom-text>
      }
    </app-footer>

    <ng-template #preloading>
      <div class="preloading"></div>
    </ng-template>

    <ng-template #circlePreloading>
      <app-preloader></app-preloader>
    </ng-template>

