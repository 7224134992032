import {AfterViewInit, Directive, ElementRef, Input, Renderer2} from '@angular/core';
import {fromEvent, of} from 'rxjs';
import {catchError, delay, filter, take, tap} from 'rxjs/operators';
import {PlatformService} from '../../services/platform.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

export enum DefaultImageType {
  GAME = '/assets/img/default-game.jpg'
}

@UntilDestroy()
@Directive({
    selector: '[defaultImage]',
    standalone: true
})
export class DefaultImage implements AfterViewInit {

  /**
   * Alternative src for image. Set if src broken
   */
  @Input() alternative: string = '';

  /**
   * Default image src. Set if src and alternative src broken
   */
  @Input() default: DefaultImageType;

  /**
   * Target image element that will be checked
   */
  private _target: HTMLImageElement;

  /**
   * Current data-src for image
   */
  private _currentSrc = '';

  constructor(
    private _el: ElementRef,
    private _platform: PlatformService,
    private _renderer: Renderer2
  ) {
  }

  ngAfterViewInit(): void {
    this._target = this._el.nativeElement instanceof HTMLImageElement ?
      this._el.nativeElement :
      this._el.nativeElement.querySelector('img');

    if (!this._target) {
      return;
    }

    fromEvent(this._target, 'error').pipe(
      untilDestroyed(this),
      catchError(error => of(error)),
      filter(() => this._platform.isBrowser),
      tap(() => {
        if (this._currentSrc !== this.alternative) {
          this._target.style.opacity = '0';
          this._currentSrc = this.alternative;
        } else if (this._currentSrc && this._currentSrc !== this.default) {
          this._currentSrc = this.default;
        }
      }),
      tap(() => {
        this._renderer.setAttribute(this._el.nativeElement, 'data-src', this._currentSrc);
      }),
      delay(100),
      tap(() => {
        this._renderer.setStyle(this._el.nativeElement, 'opacity', '1');
        this._renderer.setStyle(this._el.nativeElement, 'transition', 'all .3s');
      })
    ).subscribe();
  }
}
