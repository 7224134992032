@if (!hideText) {
  <div class="footer">
    <div class="container">
      <div class="providers-block">
        @if (paymentList$ | async; as paymentList) {
            @if (paymentList && paymentList.length > 0) {
              <h4 class="providers-block__title">{{ 't.payments' | translate }}</h4>
              <app-accordion-button [isFooter]="true">
                <div class="providers providers--first">
                  @for (payment of paymentList; track payment) {
                    <div class="providers__item" routerLink="/deposit" DeleteNotFound
                         [ngClass]="{'trustly-tuv': payment?.trustlyWithTuv}"
                    >
                      <img class="providers__item_image" ClassLoaded DeleteNotFound
                           [ngClass]="{'trustly-tuv': payment?.trustlyWithTuv}"
                           [attr.data-src]="payment?.slug?.toLowerCase() === 'trustly' ?
                  (payment?.trustlyWithTuv ? '/assets/svg/payment-methods/color/trustly-tuv.svg' : '/assets/svg/payment-methods/color/trustly-color.svg') :
                  ('https://cdn.softswiss.net//logos/payments/white/' + payment?.slug?.toLowerCase() + '.svg')" ImgDimension height="33" width="80"
                           [alt]="seo.metaTitle">
                    </div>
                  }
                </div>
              </app-accordion-button>
            }
          }
          <h4 class="providers-block__title">{{ 't.our-partners' | translate }}</h4>
          <app-accordion-button [isFooter]="true">
            @if (providerList$ | async; as providerList) {
              <div class="providers accordion">
                @for (provider of providerList; track provider) {
                  <a class="providers__item" [routerLink]="['/', lang.current, 'games', provider.alias]" DeleteNotFound
                  >
                    <img class="providers__item_image" ClassLoaded [attr.data-src]="provider.alias !== 'quickfire' ? ('https://cdn.softswiss.net//logos/providers/white/' + provider.alias + '.svg') : '/assets/svg/provider-logos/quickfire.svg'"
                         [alt]="seo.metaTitle" ImgDimension
                         [height]="provider.biggerThanOther ? 57 : provider.biggerThanOther && breakpoints.belowTablet ? 50 : this.breakpoints.belowSmallDesktop ? 24 : 33" width="80"
                         [ngClass]="{'quickfire': provider.alias === 'quickfire', 'bigger': provider.biggerThanOther}"
                    >
                  </a>
                }
                <a class="providers__item" href="https://www.softswiss.com/" target="_blank"><img class="providers__item_image" data-src="/assets/svg/softswiss-new.svg" ImgDimension height="33" width="100" [alt]="seo.metaTitle"></a>
              </div>
            }
          </app-accordion-button>
          </div>
          <div class="bottom-container">
            <div class="license-block">
              <div class="license-block__logos">
                @if (license$ | async; as license) {
                  @if (license.Url) {
                    <a class="license-block__logos-item --curacao" target="_blank" rel="nonoopener" [href]="license?.Url">
                      <img [src]="license?.Img" alt="Wildfortune casino - license">
                    </a>
                  } @else {
                    <div class="license-block__logos-item --curacao">
                      <img [src]="license?.Img" alt="Wildfortune casino - license">
                    </div>
                  }
                }
                <a class="license-block__logos-item" href="https://www.gam-anon.org" target="_blank"><img data-src="/assets/svg/security-logos/GAM-ANON.svg" [alt]="seo.metaTitle" ImgDimension [height]="33" height="33" width="60"></a>
                <a class="license-block__logos-item" href="http://www.gamblersanonymous.org" target="_blank"><img data-src="/assets/svg/security-logos/GamblersAnonymous.svg" [alt]="seo.metaTitle" ImgDimension [height]="33" height="33" width="60"></a>
                <a class="license-block__logos-item" href="https://www.gamblingtherapy.org" target="_blank"><img data-src="/assets/svg/security-logos/GamblingTherapy.svg" [alt]="seo.metaTitle" ImgDimension [height]="33" height="33" width="60"></a>
                <a class="license-block__logos-item" href="https://www.gamcare.org.uk" target="_blank"><img data-src="/assets/svg/security-logos/gamcare.svg" [alt]="seo.metaTitle" ImgDimension [height]="33" height="33" width="60"></a>
                <div class="license-block__logos-item"><img data-src="/assets/svg/security-logos/18.svg" [alt]="seo.metaTitle" ImgDimension [height]="33" height="33" width="60" ></div>
                <a class="dmca-badge license-block__logos-item" href="//www.dmca.com/Protection/Status.aspx?ID=b290ad39-cccd-4249-ba05-ed0f9a0aeb40" target="_blank" title="DMCA.com Protection Status" > <img src ="/assets/img/dmca.png" [alt]="seo.metaTitle" ImgDimension [height]="33" height="57" width="121" /></a>
                <!-- <a class="license-block__logos-item" href=""><img src="/assets/img/licenses/curacao.png" alt=""></a> -->
              </div>
            </div>
            @if (copyright$ | async; as copyright) {
              <div class="copyright"  [innerHTML]="copyright[0]?.text | safe: 'html'"></div>
            }
            <ng-content></ng-content>
          </div>
        </div>
      </div>
    }
