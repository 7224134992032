@if (timeLive) {
  <div class='timer'>
    <div class='timer__label'>{{ 't.finished-in' | translate }}</div>
    <div class='timer__value text-gradient'>{{timeLive?.days}}</div>
    <div class='timer__label'>{{timeLive?.daysLabel | translate}} :</div>
    <div class='timer__value text-gradient'>{{timeLive?.hours}}</div>
    <div class='timer__label'>{{timeLive?.hoursLabel | translate}} :</div>
    <div class='timer__value text-gradient'>{{timeLive?.minutes}}</div>
    <div class='timer__label'>{{timeLive?.minLabel | translate}}</div>
  </div>
}
