import {Component, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgModel,
  ReactiveFormsModule
} from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import {CommonModule} from '@angular/common';

import {CommonDataService, CountryList$} from '../../services/common-data.service';
import {UserPhoneService} from '../../services/user/user-phone.service';
import {EnvironmentService} from '../../services/environment.service';
import {PHONE_MASKS} from '../../helpers/phone-masks';

import {NgxMaskDirective, provideNgxMask} from 'ngx-mask';
import {UserInfoService} from '../../services/user/user-info.service';
import {TranslatePipe} from '../../shared/translation/translate.pipe';
import {ClickOutsideDirective} from '../../shared/directives/click-outside.directive';

@Component({
  selector: 'app-phone-form',
  templateUrl: './phone-form.component.html',
  styleUrls: ['./phone-form.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    TranslatePipe,
    ClickOutsideDirective
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneFormComponent),
      multi: true
    },
    provideNgxMask()
  ]
})
export class PhoneFormComponent implements OnInit, ControlValueAccessor {

  @Input() showLabel = true;

  @Input() label: string;

  @Input() usePrefix: boolean = true;

  /**
   * Input model
   */
  @ViewChild('inputModel') inputModel: NgModel;

  /**
   * Current flag country
   */
  public currentCountry: string;

  /**
   * Search control
   */
  public search: FormControl = new FormControl('');

  /**
   * Is country list open
   */
  private _open: boolean;

  /**
   * Country list after filter
   */
  public filteredCountryList$: ReplaySubject<any[]> = CountryList$;

  onChange: any = () => {
  }

  onTouch: any = () => {
  }

  set value(val) {
    let resolvedValue = '';

    if (this.usePrefix) {
      resolvedValue = this.userInfo.isFR && val?.slice(0, 2) === '33' ?
        '+' + val : this.userInfo.isFR && val?.slice(0, 3) === '+33' ?
          val : this.userInfo.isFR ? '+33' + val : this.userPhone.prefix + val;
    } else {
      resolvedValue = val?.startsWith(this.userPhone.prefix?.slice(1) || this.env.env?.country?.callingCode) ? val?.replace(this.userPhone.prefix?.slice(1) || this.env.env?.country?.callingCode, '') : val;
    }

    this.onChange(resolvedValue);
    this.onTouch(resolvedValue);
  }

  get isOpen(): boolean {
    return this._open;
  }

  constructor(
    public userPhone: UserPhoneService,
    public env: EnvironmentService,
    public userInfo: UserInfoService,
    private _data: CommonDataService
  ) {
    this.env.env$.pipe(
      first(),
      tap(({data}) => {
        this.onSelect(data.country);
      })
    ).subscribe();
  }

  ngOnInit() {
    this._onHandleSearch();
  }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  /**
   * Open countries list
   */
  onOpen() {
    this._open = true;
  }

  /**
   * Close countries list
   */
  onClose() {
    this._open = false;
  }

  onSelect(country) {
    this.onClose();
    this.currentCountry = country.short;
    this.userPhone.Prefix = this.userInfo.isFR ? '' : `+${ country.callingCode }`;
    this.userPhone.Mask = PHONE_MASKS.find(item => item.country.toLowerCase() === country.short);
    this.inputModel?.reset();
  }

  /**
   * Handle search form and filter countries
   *
   * @private
   */
  private _onHandleSearch() {
    this.search.valueChanges.pipe(
      tap(value => this.filteredCountryList$.next(
        this._data.countryList.filter(country => country.name.toLowerCase().includes(value.toLowerCase()))
      ))
    ).subscribe();
  }

}
