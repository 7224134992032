<div class="article" [ngClass]="{preloader: preloader}">
  <div class="article__media" [ngClass]="{video: article?.video}">
    @if (article?.video) {
      <iframe class="article__image" [src]="article?.video" allow="fullscreen" allowfullscreen></iframe>
    }
    @if (!article?.video && (article?.PreviewImage || article?.Image)) {
      <img class="article__image" [routerLink]="'/blog/' + article?.slug" [src]="article?.PreviewImage || article?.Image" alt="">
    }
  </div>
  <h3 class="article__title" [routerLink]="'/blog/' + article?.slug">{{ article?.Title }}</h3>
  <time class="article__date">
    {{ article?.published.date | date:'dd-MM-y' }}
  </time>
</div>
