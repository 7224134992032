import { Observable } from 'rxjs';

type ValuesOf<T> = T[keyof T];

export type ABTestTaskListType = keyof typeof abTestList;
export type HeaderMainGroupType = ValuesOf<{
  [K in keyof typeof abTestList]: typeof abTestList[K]['headerMainGroup'];
}>;
export type ABVariantsType = ValuesOf<{
  [K in keyof typeof abTestList]: ValuesOf<typeof abTestList[K]['abTestGroupIds']>;
}>;

export interface ABTestItem<K extends keyof typeof abTestList> {
  nameOfTask?: ABTestTaskListType;
  alias?: string;
  headerMainGroup: HeaderMainGroupType;
  abTestVariants: ABVariantsType[];
  abTestGroupIds: Record<keyof typeof abTestList[K]['abTestGroupIds'], ABVariantsType>;
  resolvedValue?: ABVariantsType;
  resolvedValue$?: Observable<ABVariantsType>;
  resolvedCookieValue?: ABVariantsType;
  syncOnAuth?: boolean;
  debug?: boolean;
};

export const AB_TEST_LIST: {
  [K in keyof typeof abTestList]: ABTestItem<K>;
} = {} as any;

export const abTestList = {
  'DEVWFC1406': {
    alias: 'DEVWFC1406',
    headerMainGroup: 'ID367',
    abTestGroupIds: {
      V1: 'ID367',
      V2: 'ID368',
    },
    syncOnAuth: true,
    debug: true
  },
  'DEVWFC1713': {
    alias: 'DEVWFC1713',
    headerMainGroup: 'ID392',
    abTestGroupIds: {
      V1: 'ID391',
      V2: 'ID392',
    },
    syncOnAuth: true,
    debug: true
  },
  'DEVWFC1914': {
    alias: 'DEVWFC1914',
    headerMainGroup: 'ID428',
    abTestGroupIds: {
      V1: 'ID428',
      V2: 'ID429',
    },
    syncOnAuth: true,
    debug: true
  },
  'DEVWFC2055': {
    alias: 'DEVWFC2055',
    headerMainGroup: 'ID479',
    abTestGroupIds: {
      V1: 'ID479',
      V2: 'ID480',
    },
    syncOnAuth: true,
    debug: true
  },
  'DEVWFC2464': {
    alias: 'DEVWFC2464',
    headerMainGroup: 'ID584',
    abTestGroupIds: {
      V1: 'ID584',
      V2: 'ID585',
    },
    syncOnAuth: true,
    debug: true
  },
  'DEVWFC2680': {
    alias: 'DEVWFC2680',
    headerMainGroup: 'DEVWFC2680B',
    abTestGroupIds: {
      V1: 'DEVWFC2680A',
      V2: 'DEVWFC2680B',
    },
    syncOnAuth: true,
    debug: true
  }
} as const;
