import { Injectable } from '@angular/core';
import { CmsApiService } from '../../core/services/api/cms-api.service';
import { CmsContentMapperService } from '../../core/services/cms-content-mapper.service';
import { UpdateOnLangChange } from '../../core/shared/decorators/update-on-lang-change';
import { ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TimeService } from '../../core/services/time.service';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(
    private _api: CmsApiService,
    private _mapper: CmsContentMapperService,
    private _sanitizer: DomSanitizer,
    private _time: TimeService
  ) { }

  /**
   * Get news list from CMS and prepare for using in frontend
   *
   * @param params
   */
  @UpdateOnLangChange('news/list')
  list(params: object = {}): ReplaySubject<any> {
    return this._api.newsList(params).pipe(
      map(response => this._mapArticleList(this._mapper.mapCmsData(response.data.list, {
        id: 'id',
        slug: 'alias',
        type: 'type',
        publishAt: 'publishAt.date'
      })))
    ) as ReplaySubject<any>;
  }

  /**
   * Get news item from CMS and prepare for using in frontend
   *
   * @param params
   */
  @UpdateOnLangChange('news/item')
  item(params: object = {}): ReplaySubject<any> {
    return this._api.newsItem(params).pipe(
      map(response => {
        if (response.statusCode === 200) {
          return this._mapArticleList(this._mapper.mapCmsData(response.data, {
            id: 'id',
            slug: 'alias',
            type: 'type',
            publishAt: 'publishAt.date',
            unpublishAt: 'unpublishAt.date'
          }));
        } else {
          return [{
            statusCode: response.statusCode
          }];
        }
      })
    ) as ReplaySubject<any>;
  }

  /**
   * Prepare article list for using in frontend
   *
   * @param list
   * @private
   */
  private _mapArticleList(list: any[]): any[] {
    return list.map(article => ({
      ...article,
      video: this._prepareVideoSrc(article.Video),
      published: this._getDateLabel(article.publishAt)
    }));
  }

  /**
   * Returns correct video src by provided Vimeo link
   *
   * @param src
   * @private
   */
  private _prepareVideoSrc(src: string): SafeResourceUrl {
    if (!src) {
      return;
    }

    const videoId = src.split('/').pop();

    return this._sanitizer.bypassSecurityTrustResourceUrl(`https://player.vimeo.com/video/${videoId}?title=0&byline=0&portrait=0`);
  }

  /**
   * Returns correct date label by provided date string
   *
   * @param date
   * @private
   */
  private _getDateLabel(date: string): {num: number|string, label: string, date: Date} {
    const daysCount = this._time.timeDiff(this._time.toLocalDate(date)).days;
    const label = daysCount > 6
      ? this._time.toLocalDate(date).toString()
      : daysCount === 0
        ? 't.today'
        : daysCount === 1
          ? 't.yesterday'
          : 't.days-ago';

    return {
      num: daysCount > 1 ? daysCount : '',
      label,
      date: this._time.toLocalDate(date)
    };
  }
}
