import { Component, Input, TemplateRef } from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'app-tab-item',
    styles: [
        `.tab-item {
      width: 100%;
    }
    `
    ],
    template: `
    <div [hidden]="!active" class="tab-item">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>

    <ng-template #content>
      <ng-content></ng-content>
    </ng-template>
  `,
    standalone: true,
    imports: [NgTemplateOutlet]
})
export class TabItemComponent {

  public title: string = null;
  public titleTpl: TemplateRef<any> = null;

  @Input('badge') badge: string | number;
  @Input('active') active = false;
  @Input('disabled') disabled = false;
  @Input('alt') alt = '';
  @Input('id') id: number | string = null;

  @Input('tabTitle')
  set tabTitle(value: string | TemplateRef<any>) {
    if (value instanceof TemplateRef) {
      this.titleTpl = value;
    } else {
      this.title = value;
    }
  }

  constructor() {

  }

}
