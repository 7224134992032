
@switch (type) {
  @case (bonusTypes.WELCOME) {
    <ng-container *ngTemplateOutlet="welcomeBonus"></ng-container>
  }
  @case (bonusTypes.CASH) {
    <ng-container *ngTemplateOutlet="cashBonus"></ng-container>
  }
  @case (bonusTypes.FREESPINS) {
    <ng-container *ngTemplateOutlet="freespinsBonus"></ng-container>
  }
  @case (bonusTypes.LOOTBOX) {
    <ng-container *ngTemplateOutlet="lootbox"></ng-container>
  }
  @case (bonusTypes.NDFS) {
    <ng-container *ngTemplateOutlet="ndfs"></ng-container>
  }
}

<!-- START WELCOME BONUS -->
<ng-template #welcomeBonus>
  <div class="bonus welcome">
    <ng-container [ngTemplateOutlet]="label"></ng-container>
    <div class="bonus__info">
      <div class="bonus__top">
        <div class="bonus__top_left">
          <div class="bonus__top_right">
            <img class="welcome-img" [src]="bonus?.imgSrc" alt="welcome bonus">
          </div>
          <div class="bonus__title">{{bonus?.bonus?.title}}</div>
          <div class="bonus__amount">
            <div class="info__line">
              <div class="info__line-title">
                {{ bonus?.bonus?.attributes?.bonus_amount_percent }}%
                @if (bonus?.bonus?.attributes?.bonus_amount_max) {
                  {{ "t.up-to" | translate }}
                  <span class="nowrap">
                    {{ bonus?.bonus?.attributes?.bonus_amount_max[user.info.currency] }}
                    {{ data.currencySymbol(user.info.currency) }}
                  </span>
                }
                @if (bonus?.freespins?.attributes?.freespins_count) {
                  <div>
                    + {{bonus?.freespins?.attributes?.freespins_count}} {{'t.free-spins' | translate}}
                  </div>
                }
              </div>
            </div>
          </div>
          <div class="bonus__additional-info">
            @if (bonus?.bonus?.conditions?.amount_min) {
              <div
                class="info__line"
                >
                <span>{{ "t.min-dep" | translate }}:</span>
                <span>
                  {{ data.currencySymbol(user.info.currency) }} {{bonus?.bonus.conditions?.amount_min[user.info.currency] }}
                </span>
              </div>
            }
            @if (bonus?.bonus?.conditions?.amount_max) {
              <div
                class="info__line"
                >
                <span>{{ "t.max-deposit" | translate }}:</span>
                <span>
                  {{ data.currencySymbol(user.info.currency) }} {{bonus?.bonus?.conditions?.amount_max[user.info.currency] }}
                </span>
              </div>
            }
            @if (bonus?.bonus?.attributes?.wager) {
              <div class="info__line">
                <span>{{ "t.wager" | translate }}:</span>
                <span> x{{ bonus?.bonus?.attributes?.wager }}</span>
              </div>
            }

          </div>
        </div>
      </div>
      <div class="bonus__bottom">
        <div class="bonus__actions deposit">
          <button routerLink="/deposit" class="btn btn--filled-primary">{{ 't.use-bonus' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- END WELCOME BONUS -->

<!-- START CASH BONUS -->
<ng-template #cashBonus>
  <div class="bonus cash">
    <ng-container [ngTemplateOutlet]="label"></ng-container>
    <div class="bonus__info">
      <div class="bonus__top">
        <div class="bonus__top_left">
          <div class="bonus__top_right">
            <img src="/assets/img/new-bonuses/cash.png" class="cash-img" alt="Cash bonus image">
          </div>
          <div class="bonus__title" [innerHTML]="'t.cash-bonus' | translate | safe: 'html'"></div>
          <div class="bonus__amount">
            {{bonus?.currency_symbol}} {{bonus?.amount}}
          </div>
        </div>
        <div class="bonus__wager">
          {{ 't.wager' | translate }}:
          {{ bonus?.amount_wager_cents / 100 | number:'1.2-2' }} {{ bonus?.currency_symbol }} /
          {{ bonus?.amount_wager_requirements | number:'1.2-2' }} {{ bonus?.currency_symbol }} <br>
          ({{ bonus?.wager | number:'1.2-2' }}%)
        </div>
        @if ((bonus?.stage !== BonusStage.ISSUED && bonus?.stage !== BonusStage.HANDLE_BETS) || (!bonus?.activatable && bonus?.active)) {
          <div class="wager-line">
            <div class="wager-line-bar" [style.width.px]="bonus?.wager | number: '1.0-0'"></div>
          </div>
        }
      </div>

      <div class="bonus__bottom">
        @if (!(bonus?.stage === BonusStage.FINISHED || bonus?.stage === BonusStage.EXPIRED)) {
          <div class="bonus__actions">
            @if ((bonus?.stage === BonusStage.ISSUED) && bonus?.activatable) {
              <button class="btn btn--filled-primary" (click)="onActivate.next(bonus)">{{ 't.activate' | translate }}</button>
            }
            @if (bonus?.stage !== BonusStage.WAGER_DONE) {
              <button class="btn btn--filled-secondary big" (click)="onCancel.next(bonus)">{{ 't.cancel' | translate }}</button>
            }
          </div>
        }
        @if (!(bonus?.stage === BonusStage.FINISHED || bonus?.stage === BonusStage.EXPIRED)) {
          <div class="bonus__timer">
            <app-bonus-timer [timeLeft]="bonus?.valid_until || bonus?.activatable_until"></app-bonus-timer>
          </div>
        } @else {
          <div class="finished">
            {{ 't.finished' | translate }}: {{ (bonus?.valid_until || bonus?.activatable_until) | date: 'd-M-yyyy HH:mm' }}
          </div>
        }
      </div>
    </div>
  </div>
</ng-template>
<!-- END CASH BONUS -->

<!-- START FREESPINS BONUS -->
<ng-template #freespinsBonus>
  <div class="bonus freespins">
    <ng-container [ngTemplateOutlet]="label"></ng-container>
    @if (bonus?.games_info?.[0]) {
      @if (bonus?.games_info?.[0] | async; as firstGame) {
        <div class="bonus__info">
          <div class="bonus__top">
            <div class="bonus__top_left">
              <div class="bonus__top_right">
                <a [routerLink]="['/play', firstGame?.slug]">
                  <img [src]="bonus?.imgSrc" [alt]="firstGame?.name">
                </a>
              </div>
              <div class="bonus__title" [innerHTML]="'t.free-spins' | translate | safe: 'html'"></div>
              <div class="bonus__amount">{{ bonus.freespins_total }} {{ "t.free-spins" | translate }}</div>
              @if (firstGame) {
                <div class="bonus__game">
                  <span class="games">
                    @if (firstGame?.name) {
                      <a [routerLink]="['/play', firstGame?.slug]">{{firstGame?.name}}</a>
                    }
                    @if (bonus?.games_info?.length - 1 > 0) {
                      <span (click)="navigateToAllGames(bonus?.games_info); $event.stopPropagation()" class="games__more"> & <br> <span>{{bonus?.games_info?.length - 1}}{{ bonus?.useAndSymbolInTemplate ? '+' : '' }} {{'t.games' | translate}}</span></span>
                    }
                  </span>
                </div>
              }
            </div>
            @if (bonus?.wager) {
              <div class="bonus__wager">{{ 't.wager' | translate }}: {{bonus?.wager}}x</div>
            }
          </div>
          <div class="bonus__bottom">
            @if (!(bonus?.stage === BonusStage.FINISHED || bonus?.stage === BonusStage.EXPIRED) && firstGame) {
              <div class="bonus__actions">
                @if (bonus?.stage === BonusStage.ACTIVATED) {
                  <button class="btn btn--filled-primary" [routerLink]="['/play', firstGame?.slug]">{{ 't.play' | translate }}</button>
                }
                @if (bonus?.stage === BonusStage.ISSUED || bonus?.stage === BonusStage.HANDLE_BETS) {
                  <button class="btn btn--filled-primary" (click)="onActivate.next(bonus)">{{ 't.activate' | translate }}</button>
                }
                <button class="btn btn--filled-secondary" (click)="onCancel.next(bonus)">{{ 't.cancel' | translate }}</button>
              </div>
            }
            @if (!(bonus?.stage === BonusStage.FINISHED || bonus?.stage === BonusStage.EXPIRED)) {
              <div class="bonus__timer">
                <app-bonus-timer [timeLeft]="bonus?.valid_until || bonus?.activatable_until"></app-bonus-timer>
              </div>
            } @else {
              <div class="finished">
                {{ 't.finished' | translate }}: {{ (bonus?.valid_until || bonus?.activatable_until) | date: 'd-M-yyyy HH:mm' }}
              </div>
            }
          </div>
        </div>
      }
    } @else {
      <div class="bonus__info">
        <div class="bonus__top">
          <div class="bonus__top_left">
            <div class="bonus__top_right">
              <img [src]="bonus?.imgSrc" [alt]="'Free spins - wildfortune casino'">
            </div>
            <div class="bonus__title" [innerHTML]="'t.free-spins' | translate | safe: 'html'"></div>
            <div class="bonus__amount">{{ bonus.freespins_total }} {{ "t.free-spins" | translate }}</div>
          </div>
          @if (bonus?.wager) {
            <div class="bonus__wager">{{ 't.wager' | translate }}: {{bonus?.wager}}x</div>
          }
        </div>
        <div class="bonus__bottom">
          @if (!(bonus?.stage === BonusStage.FINISHED || bonus?.stage === BonusStage.EXPIRED)) {
            <div class="bonus__actions">
              @if (bonus?.stage === BonusStage.ISSUED || bonus?.stage === BonusStage.HANDLE_BETS) {
                <button class="btn btn--filled-primary" (click)="onActivate.next(bonus)">{{ 't.activate' | translate }}</button>
              }
              <button class="btn btn--filled-secondary" (click)="onCancel.next(bonus)">{{ 't.cancel' | translate }}</button>
            </div>
          }
          @if (!(bonus?.stage === BonusStage.FINISHED || bonus?.stage === BonusStage.EXPIRED)) {
            <div class="bonus__timer">
              <app-bonus-timer [timeLeft]="bonus?.valid_until || bonus?.activatable_until"></app-bonus-timer>
            </div>
          } @else {
            <div class="finished">
              {{ 't.finished' | translate }}: {{ (bonus?.valid_until || bonus?.activatable_until) | date: 'd-M-yyyy HH:mm' }}
            </div>
          }
        </div>
      </div>
    }
  </div>
</ng-template>
<!-- END FREESPINS BONUS -->

<!-- START LOOTBOX BONUS -->
<ng-template #lootbox>
  <div class="bonus lootbox">
    <ng-container [ngTemplateOutlet]="label"></ng-container>
    <div class="bonus__info">
      <div class="bonus__top">
        <div class="bonus__top_right">
          <img src="/assets/img/new-bonuses/lootbox.png" alt="Lootbox image">
        </div>
        <div class="bonus__top_left">
          <div class="bonus__title">{{(bonus?.title || defaultTranslates?.title) | translate }}</div>
          @if (bonus?.cardDesc) {
            <div class="bonus__desc" [innerHTML]="bonus?.cardDesc | translate | safe: 'html'"></div>
          }
        </div>
      </div>
      <div class="bonus__bottom">
        @if (!(bonus?.stage === BonusStage.FINISHED || bonus?.stage === BonusStage.EXPIRED)) {
          <div class="bonus__actions">
            <button class="btn btn--filled-primary" (click)="openLootbox(bonus)">{{ 't.open' | translate }}</button>
            <button class="btn btn--filled-secondary" (click)="onCancel.next(bonus)">{{ 't.cancel' | translate }}</button>
          </div>
        }
        @if (!(bonus?.stage === BonusStage.FINISHED || bonus?.stage === BonusStage.EXPIRED)) {
          <div class="bonus__timer">
            <app-bonus-timer [timeLeft]="bonus?.valid_until || bonus?.activatable_until"></app-bonus-timer>
          </div>
        } @else {
          <div class="finished">
            {{ 't.finished' | translate }}: {{ (bonus?.valid_until || bonus?.activatable_until) | date: 'd-M-yyyy HH:mm' }}
          </div>
        }
      </div>
    </div>
  </div>
</ng-template>
<!-- END LOOTBOX BONUS -->

<!-- START NDFS BONUS -->
<ng-template #ndfs>
  <div class="bonus welcome ndfs">
    <ng-container [ngTemplateOutlet]="label"></ng-container>
    <div class="bonus__info">
      <div class="bonus__top">
        <div class="bonus__top_left">
          <div class="bonus__top_right">
            <img class="welcome-img" src="/assets/img/ndfs/ndfs.png" alt="Wildfortune casino - ndfs bonus">
          </div>
          <div class="bonus__title">{{ 't.free-spins' | translate }}</div>
          <div class="bonus__additional-info">
            <div class="ndfs__desc">{{ 't.ndfs-email-title' | translate }}</div>
          </div>
        </div>
      </div>
      <div class="bonus__bottom">
        <div class="bonus__actions deposit">
          <div class="bonus__confirm">
            <img src="/assets/img/ndfs/attention.svg" alt="">
            {{ 't.confirm-email-activate' | translate }}
          </div>
          <button (click)="onConfirm()" class="btn btn--filled-primary">{{ 't.confirm-email' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- END NDFS BONUS -->

<ng-template #label>
  @if (bonus?.frontend_label) {
    <span class="bonus__label" [innerHTML]="((bonus?.stage === BonusStage.ACTIVATED || bonus?.stage === BonusStage.HANDLE_BETS ? '<span>● </span>' : '') + (bonus?.frontend_label | translate)) | safe: 'html'"
        [ngClass]="{
      active: bonus?.stage === BonusStage.ACTIVATED || bonus?.stage === BonusStage.HANDLE_BETS,
      expired: bonus?.stage === BonusStage.EXPIRED,
      finished: bonus?.stage === BonusStage.FINISHED
    }"
  ></span>
}
</ng-template>

