import { Component, Input, OnInit } from '@angular/core';
import { SafePipe } from '../../pipes/safe.pipe';
import { AccordionDirective } from '../../directives/accordion.directive';
import {AccordionButtonComponent} from '../../components/accordion-button/accordion-button.component';


@Component({
    selector: 'app-bottom-text',
    templateUrl: './bottom-text.component.html',
    styleUrls: ['./bottom-text.component.scss'],
    standalone: true,
  imports: [AccordionDirective, SafePipe, AccordionButtonComponent]
})
export class BottomTextComponent implements OnInit {

  @Input('text') text: string;

  constructor() { }

  ngOnInit() {
  }

}
