import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SsApiService } from '../../services/api/ss-api.service';
import { UserService } from '../../services/user/user.service';
import { ValidationPatterns } from '../validation-patterns';
import {catchError, debounceTime, filter, map, switchMap, tap} from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { CommonDataService, isCryptoAcc } from '../../services/common-data.service';
import { EnvironmentService } from '../../services/environment.service';
import { PlatformService } from '../../services/platform.service';
import { ToastMessageService } from '../../modules/toast-message/toast-message.service';
import { CacheService } from '../../services/cache/cache.service';
import { ContentUpdaterService } from '../../services/content-updater/content-updater.service';
import { ERROR_MESSAGES, FormsErrorHandlerService } from '../../services/forms-error-handler.service';
import { CustomValidators } from '../custom-validators';
import { EventEmitter, inject } from '@angular/core';
import { LocalstorageService } from '../../services/localstorage.service';
import { UserInfoService } from '../../services/user/user-info.service';
import { Router } from '@angular/router';
import { GroupsService } from '../../services/groups.service';
import { GoogleTagManagerService } from '../../services/google-tag-manager.service';
import { CookieService } from 'ngx-unificator/services';
import { HttpErrorResponse } from '@angular/common/http';
import {ModalService} from '../../modal-v2/modal.service';
import {AdmilService} from '../../services/admil.service';
import {UserSubscriptionsService} from '../../services/user/user-subscriptions.service';

const fieldsToSaveLocalStorage = ['email', 'password', 'nickname'];

const registrationStorageName = '__registrationData';

export class RegisterFormController {

  /**
   * Access to global Services
   */
  private _fb: UntypedFormBuilder = inject(UntypedFormBuilder);
  private _ssApi: SsApiService = inject(SsApiService);
  private _user: UserService = inject(UserService);
  private _userInfo: UserInfoService = inject(UserInfoService);
  private _commonData: CommonDataService = inject(CommonDataService);
  private _env: EnvironmentService = inject(EnvironmentService);
  private _platform: PlatformService = inject(PlatformService);
  private _toastMessage: ToastMessageService = inject(ToastMessageService);
  private _cache: CacheService = inject(CacheService);
  private _contentUpdater: ContentUpdaterService = inject(ContentUpdaterService);
  private _formErrors: FormsErrorHandlerService = inject(FormsErrorHandlerService);
  private _localStorage: LocalstorageService = inject(LocalstorageService);
  private _modal: ModalService = inject(ModalService);
  private _router: Router = inject(Router);
  private _groups: GroupsService = inject(GroupsService);
  private _gtm: GoogleTagManagerService = inject(GoogleTagManagerService);
  private _cookie: CookieService = inject(CookieService);
  private _admil: AdmilService = inject(AdmilService);
  private _subscriptions: UserSubscriptionsService = inject(UserSubscriptionsService);


  /**
   * Is loading state
   */
  private _loading: boolean;

  /**
   * Is loading request for check email
   */
  private _loadingEmail: boolean;

  /**
   * Emits updating result
   */
  public registered$: EventEmitter<any> = new EventEmitter<any>();
  public error$: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Suggestion lists
   */
  public citiesSuggestion: Array<any> = [];
  public addressesSuggestion: Array<any> = [];
  /**
   * Login for FormGroup
   */
  private _form: UntypedFormGroup = this._fb.group({
    email: [null, [Validators.required, Validators.pattern(ValidationPatterns.email)]],
    password: [null, [Validators.required, CustomValidators.changeKey('password_pattern', Validators.pattern(ValidationPatterns.password))]],
    password_confirmation: [null, [Validators.required]],
    nickname: [this._generateNickname(), [Validators.required, CustomValidators.checkSpaces]],
    country: [null],
    currency: [null, [Validators.required]],
    receive_promos: [false],
    receive_sms_promos: [false],
    terms_acceptance: [false, [CustomValidators.changeKey('required_true', Validators.requiredTrue)]],
    age_acceptance: [true, [CustomValidators.changeKey('required_true', Validators.requiredTrue)]],
    bonus_code: [null, Validators.pattern(ValidationPatterns.lettersAndNumbers)],
  });

  /**
   * Boolean variable to check if controller used for form with additional fields
   */
  public isNewFormVersion = false;

  constructor(isNewForm?: boolean) {
    this.isNewFormVersion = isNewForm;

    this._setAutoDetectedValues();
    this._pasteDataFromStorageToForm();
    this._subscriptions.receiveEmailPromosValue$.subscribe(() => this.form.get('receive_promos').setValue(true));
    this._subscriptions.receiveTermsAcceptanceValue$.subscribe(() => this.form.get('terms_acceptance').setValue(true));
  }

  /**
   * Access to private properties from outside
   */
  get form(): UntypedFormGroup { return this._form; }
  get loading(): boolean { return this._loading; }
  get loadingEmail(): boolean { return this._loadingEmail; }

  /**
   * Returns FormControl by key
   *
   * @param key
   */
  input(key: string): AbstractControl {
    return this._form.get(key);
  }

  /**
   * Submit form handler
   */
  submitForm() {
    this._setAdditionalFields();

    this._formErrors.applyFormErrors(this._form, null, true);

    if (this._form.invalid) {
      return;
    }
    this._loading = true;
    const bonusCode = this.form.getRawValue().bonus_code;

    const value = {
      ...this.form.value,
      receive_promos: true,
      bonus_code: bonusCode ? bonusCode.toUpperCase() : bonusCode
    };

    if (this._userInfo.isFR) {
      delete value.country;
    }

    this._ssApi.usersSignUp({
      user: value
    }).pipe(
      tap(response => {
        /**
         * For autologin
         */
        this._cache.clear();
        this._user.applyUserInfo(response, this.isNewFormVersion);
        this._addUserToCryptoGroup(response);
        this._admil.onRegister(response);

        forkJoin([
          this._user.getUserAccounts(),
          this._user.getUserAccountsCompatibility()
        ]).subscribe();

        this._contentUpdater.updateAll();

        this._gtm.signUpSuccessTimestamp('reg_succ_time', 'reg_succ', response.id);
        this._gtm.signUpSuccess(response.id);

        this.registered$.next(response);

        this.toggleRegistrationDataToStorage(true);
        this._loading = false;

        if (!this.isNewFormVersion) {
          this._router.navigateByUrl('/').then(() => {
            this._toastMessage.success('t.register-success');
          });
        }
      }),
      filter(() => !this.isNewFormVersion),
      switchMap(() => this._cookie.get('ptag') && this._userInfo.isFR || !this._userInfo.isFR ?
        this._userInfo.updatePlayerInfo({
          nickname: this.form.value.nickname,
          country: this._userInfo.isFR ? '' : this.form.value.country.toUpperCase()
        }, 'edition') : null),
      switchMap(() => this._cookie.get('ptag') && this._userInfo.isFR || !this._userInfo.isFR ? this._user.getUserInfo() : null),
      switchMap(() => this._userInfo.missingAttributesFor('deposit')),  
      catchError(error => {
        this._toastMessage.error('t.check-data-again');
        this._gtm.registerError('register_error', error.error.errors);
        this.error$.next(error);
        this._applySignupErrors(error);
        return of(error);
      })
    ).subscribe(() => {
      this._loading = false;
    });
  }

  /**
   * Apply signup errors
   * @param error
   * @private
   */
  private _applySignupErrors(error: HttpErrorResponse) {
    if (error?.error?.errors?.email) {
      this._formErrors.applyFormErrors(this._form, {
        errors: { email: [ERROR_MESSAGES.is_not_valid] || {} }
      }, false);
    } else {
      this._formErrors.applyFormErrors(this._form, {
        errors: {
          ...error?.error?.errors || {},
          ...error?.error?.errors && error?.error?.errors?.profile || {}
        },
      }, true);
    }
  }

  /**
   * Set values to fields that not exists in form
   *
   * @private
   */
  private _setAdditionalFields() {
    this._form.get('age_acceptance').setValue(this._form.get('terms_acceptance').value);
    this._form.get('password_confirmation').setValue(this._form.get('password').value);
  }

  /**
   * Automatically set known values for register form
   *
   * @private
   */
  private _setAutoDetectedValues() {
    if (!this._platform.isBrowser) {
      return;
    }

    this._commonData.loaded$.pipe(
      debounceTime(100),
      tap(() => {
        if (this._commonData.currencyList.some(currency => currency.code === this._env.env.currency.short)) {
          this.form.get('currency').setValue(this._env.env.currency.short);
        } else {
          this.form.get('currency').setValue(this._commonData.currencyList[0].code);
        }

        if (this._commonData.countryList.some(country => country.short === this._env.env.country.short)) {
          this.form.get('country').setValue(this._env.env.country.short);
        } else if (this._userInfo.isFR) {
          this.form.get('country').setValue('fr');
        }
      })
    ).subscribe();
  }

  /**
   * Select suggested value
   *
   * @param suggestion
   * @param input
   */
  selectSuggestion(suggestion, input: string) {
    switch (input) {
      case 'city':
        this.form.get('city').setValue(suggestion.address.city);
        this.form.get('postal_code').setValue(suggestion.address.postalCode);
        break;
      case 'address':
        let address = suggestion.address.street;

        if (suggestion.address.houseNumber) {
          address = address + ', ' + suggestion.address.houseNumber;
        }

        this.form.get('address').setValue(address);
        break;
    }
  }

  /**
   * Add user to crypto group if has crypto currency after register
   * @param response
   * @private
   */
  private _addUserToCryptoGroup(response: any) {
    if (isCryptoAcc(response.currency)) {
      this._groups.addToGroup('btcplayer').pipe(
      ).subscribe();
    }
  }

  checkIfEmailRegistered() {
    this._loadingEmail = true;

    return this._ssApi.usersSignUp({
      user: {
        email: this.form.value.email
      }
    }).pipe(
      map(response => true),
      catchError(error => {
        if (error.error.errors.email) {
          this._formErrors.applyFormErrors(this._form, {
            errors: { email: [ERROR_MESSAGES.is_not_valid] || {} }
          }, false);
          return of(false);
        } else {
          return of(true);
        }
      }),
      tap(() => this._loadingEmail = false)
    );
  }

  /**
   * Toggle cookies enter data for set if user from terms
   *
   * @param isRemove
   */
  public toggleRegistrationDataToStorage(isRemove: boolean = false) {
    if (!isRemove) {
      const regData = {};

      fieldsToSaveLocalStorage.forEach(field => regData[field] = this.input(field).value);
      this._localStorage.set(registrationStorageName, JSON.stringify(regData));
    } else {
      this._localStorage.clearItem(registrationStorageName);
    }
  }

  /**
   * Paste data in modal registration from localStorage
   * @private
   */
  private _pasteDataFromStorageToForm() {
    const data = JSON.parse(this._localStorage.get(registrationStorageName));
    if (data) {
      Object.entries(data).forEach(value => {
        this.form.patchValue({ [value[0]]: value[1] });
      });
    }
  }

  /**
   * Generate random nickname
   * @private
   */
  private _generateNickname(): string {
    let nickname = '';
    const symbols = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 12; i++) {
      nickname += symbols.charAt(Math.floor(Math.random() * symbols.length));
    }

    return nickname;
  }
}
