import {Component, forwardRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { UserService } from '../../../core/services/user/user.service';
import {SummerService, WHEEL_BONUSES} from './summer.service';
import {SummerWheelComponent} from './summer-wheel/summer-wheel.component';
import {catchError, delay, first, switchMap, tap} from 'rxjs/operators';
import {ScrollService} from '../../../core/services/scroll.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Observable, of} from 'rxjs';
import {EnvironmentService} from '../../../core/services/environment.service';
import { TranslatePipe } from '../../../core/shared/translation/translate.pipe';
import { SafePipe } from '../../../core/shared/pipes/safe.pipe';
import { FooterComponent } from '../../../core/shared/layout/footer/footer.component';
import { BottomNavigationComponent } from '../../../core/shared/layout/bottom-navigation/bottom-navigation.component';
import { AccordionDirective } from '../../../core/shared/directives/accordion.directive';
import { FontByBlockSizeDirective } from '../../../core/shared/directives/font-by-block-size.directive';
import { TournamentPreviewComponent } from '../../../core/shared/layout/tournament-preview/tournament-preview.component';
import { RouterLink } from '@angular/router';
import { RouterLinkDirective } from '../../../core/shared/directives/router-link.directive';
import { GamePreviewComponent } from '../../../core/shared/layout/game-preview/game-preview.component';
import { SliderComponent } from '../../../core/modules/slider/slider.component';
import { SummerTimerComponent } from './summer-timer/summer-timer.component';
import { NgStyle, NgTemplateOutlet, NgClass, AsyncPipe } from '@angular/common';
import { SideNavComponent } from '../../../core/shared/layout/side-nav/side-nav.component';
import { HeaderComponent } from '../../../core/shared/layout/header/header.component';

export const showHide = trigger('showHide', [
  state('void', style({
    opacity: 0,
    transform: 'scale(0.6)'
  })),
  transition('void <=> *', animate('0.2s cubic-bezier(0.465, 0.183, 0.153, 0.946)'))
]);

export const LAST_BONUS_ID = 'last-bonus';
  export const LAST_WELCOME_BONUS_SPINNED = '--last-welcome-bonus-spinned';

@Component({
    selector: 'app-summer',
    templateUrl: './summer.component.html',
    styleUrls: ['./summer.component.scss'],
    animations: [showHide],
    standalone: true,
    imports: [HeaderComponent, forwardRef(() => SideNavComponent), NgStyle, SummerTimerComponent, SummerWheelComponent, SliderComponent, GamePreviewComponent, NgTemplateOutlet, RouterLinkDirective, RouterLink, TournamentPreviewComponent, NgClass, FontByBlockSizeDirective, AccordionDirective, BottomNavigationComponent, FooterComponent, AsyncPipe, SafePipe, TranslatePipe]
})
export class SummerComponent implements OnInit, OnDestroy {
  @ViewChild('wheelComponent') wheelComponent: SummerWheelComponent;

  /**
   * Summer offer
   */
  public promo$: Observable<any> = this.summer.getPromo$();

  public onSpinClick = false;

  constructor(
    public user: UserService,
    public summer: SummerService,
    public env: EnvironmentService,
    private _scroll: ScrollService
  ) {
  }

  ngOnInit() {
    this.summer.currentPrize = null;
    this.summer.lastWelcomeId = this.summer.cookie.get(LAST_BONUS_ID);
  }


  ngOnDestroy() {
    this.summer.currentPrize = null;
  }

  public onSpinWheel() {
    this.onSpinClick = true;
    if (this.summer.isUserCanClaimAllBonuses && this.summer.cookie.check(LAST_WELCOME_BONUS_SPINNED) || this.summer.lootboxPromoBonus) {
      this._activateLootBoxAndSpin();
    } else {
      this._spinWithWelcomeBonus();
    }
  }

  private _activateLootBoxAndSpin() {
    const lootboxId = this.summer.lootboxPromoBonus.id;
    this.summer.lootbox.activateLootbox(lootboxId).pipe(
      switchMap((activatedLootbox) => {
        const fsCount = this.summer.bonuses.resolveBonusAttributes(
          activatedLootbox.bonuses[0].attributes,
        ).freespins_count;
        const findIndex = WHEEL_BONUSES.findIndex(item => item?.prize === fsCount);
        const findImage = WHEEL_BONUSES[findIndex]?.image || WHEEL_BONUSES[1]?.image;
        const spinDegree = 4320 - (45 * findIndex);
        return this.wheelComponent.onSpin$(spinDegree).pipe(
          first(),
          delay(6500),
          tap(() => this._setLootboxPrize(activatedLootbox, findImage)),
        );
      }),
      catchError(() => {
        this.onSpinClick = false;
        return of(false);
      })
    ).subscribe();
  }

  private _spinWithWelcomeBonus() {
    this.wheelComponent.onSpin$(4275).pipe(
      first(),
      delay(6500),
      tap(() => {
        const prizeImage = WHEEL_BONUSES?.find(w => w.prize === 'secret')?.image;
        const fs = this.summer.currentWelcomeBonus?.fs?.freespins_total;
        let cash;
        if (this.summer.currentWelcomeBonus?.cash) {
          cash = `${this.summer.currentWelcomeBonus?.cash?.amount} ${this.summer.currentWelcomeBonus?.cash?.currency}`;
        }
        this.summer.currentPrize = { image: prizeImage, fs_count: fs, cash };
        this.summer.lastWelcomeId = this.summer.currentWelcomeBonus?.cash?.id || this.summer.currentWelcomeBonus?.fs?.id;
        this.summer.cookie.set(LAST_BONUS_ID, this.summer.lastWelcomeId);

        if (this.summer.isUserCanClaimAllBonuses) {
          this.summer.cookie.set(LAST_WELCOME_BONUS_SPINNED, '1');
        }

        this.onSpinClick = false;
      }),
    ).subscribe();
  }

  private _setLootboxPrize(activatedLootbox: any, image: string) {
    const fs = this.summer.bonuses.resolveBonusAttributes(
      activatedLootbox.bonuses[0].attributes,
    ).freespins_count;
    this.summer.currentPrize = {
      image,
      fs_count: fs,
    };
    this.onSpinClick = false;
  }


  public scrollToWheel() {
    requestAnimationFrame(() => this._scroll.scrollToElement(this._scroll.documentElement));
  }
}
