<div class="offer__wrapper">
  @if (offer?.type === reloadPhoneVerification) {
    <div class="offer offer--reload offer--phone"
      [style.backgroundColor]="offer.color"
      >
      <div class="offer__content offer__content_reload">
        <div class="offer__top">
          <div class="offer__title" [innerHTML]="offer?.title | safe:'html'"></div>
          <div class="offer__description" [innerHTML]="offer?.description | safe:'html'"></div>
        </div>
        <div class="offer__actions">
          <a class="btn btn--filled-primary big" (click)="onBonusClick([this.lang.current, 'account', 'profile'])">
            {{'t.get-bonus' | translate}}
          </a>
        </div>
        <div class="offer__how-it-works" (click)="openPhoneTermsInfoModal()">{{ 't.how-earn-bonus' | translate }}</div>
      </div>
      <img class="offer__image" [src]="offer.image | safe:'url'" [alt]="seo.metaTitle" [title]="offer.title">
    </div>
  } @else {
    <div class="offer"
      [style.backgroundColor]="offer.type === welcomeBonus ||  offer.type === reloadBonus || offer.type === dailyBonus || isCurrentLoyalty || offer?.type === blogBonus ? offer.color : ''"
         [ngClass]="{taken: isActiveLoyalty, current: isCurrentLoyalty, 'not-active': isNotActiveLoyalty && !isActiveLoyalty,
       'offer--welcome': offer.type === welcomeBonus,
       'offer--reload': offer.type === reloadBonus || offer?.type === blogBonus,
       'offer--loyalty': offer.type === loyaltyBonus,
       'offer--daily': offer.type === dailyBonus,
       'offer--phone': offer?.type === blogBonus
     }"
      >
      <div class="offer__content" [ngClass]="{
    'offer__content_welcome': offer.type === welcomeBonus,
    'offer__content_reload': offer.type === reloadBonus || offer?.type === blogBonus,
    'offer__content_loyalty': offer.type === loyaltyBonus
   }">
        <div class="offer__top">
          @if (isCurrentLoyalty) {
            <div class="offer__you">{{ 't.you-are' | translate }}</div>
          }
          <div class="offer__title" [innerHTML]="offer?.title | safe:'html'"
            [ngClass]="{'taken': offer?.taken, 'missed': offer?.missed}"
          ></div>
          <div class="offer__description" [innerHTML]="offer?.description | safe:'html'"></div>
        </div>
        <div class="offer__actions">
          <a class="btn btn--filled-primary big" [routerLink]="user.auth ? '/deposit' : '/register'"
            [ngClass]="{hidden: offer?.taken || offer?.missed || isNotActiveLoyalty}"
            >
            {{ buttonTitle ? (buttonTitle | translate) : (isCurrentLoyalty ? 't.get-next-bonus' : 't.get-bonus') | translate}}
          </a>
        </div>
        @if (offer?.type === blogBonus) {
          <div class="offer__how-it-works" [routerLink]="offer?.route">{{ 't.how-earn-bonus' | translate }}</div>
        }
      </div>
      <img class="offer__image" [src]="offer.image | safe:'url'" [alt]="seo.metaTitle" [title]="offer.title">
    </div>
    @if (offer?.topLabel) {
      <div class="offer__fixed_top-text">
        {{ offer?.topLabel }}
      </div>
    }
    @if (offer?.secondDescription) {
      <div class="offer__fixed_bottom-text" [style.backgroundColor]="offer.color">
        <div class="count-fs">
          <span>{{ offer?.countFS || '20' }}{{ 't.fs' | translate }}</span>
          <span>{{ 't.day' | translate}}</span>
        </div>
        {{ offer?.secondDescription }}
      </div>
    }
  }
</div>

