import {Component, ElementRef, Input, Renderer2, ViewChild} from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'app-summer-wheel',
    templateUrl: './summer-wheel.component.html',
    styleUrls: ['./summer-wheel.component.scss'],
    standalone: true
})
export class SummerWheelComponent {
  @ViewChild('smallerDiskWheel') smallerDiskWheel: ElementRef;
  @ViewChild('prizesWheel', { static: false }) prizesWheel: ElementRef;

  @Input() wheelPrizesImage: string;

  constructor(
    private _renderer: Renderer2
  ) {
  }

  public onSpin$(degree: number): Observable<any> {
    return of(true).pipe(
      tap(() => {
        this._renderer.setStyle(this.prizesWheel.nativeElement, 'transform', `rotate(${degree}deg)`);
        this._renderer.setStyle(this.smallerDiskWheel.nativeElement, 'transform', `rotate(${-degree}deg)`);

        if (this.prizesWheel.nativeElement?.parentElement) {
          this._renderer.setStyle(this.prizesWheel.nativeElement?.parentElement, 'animation', `none`);
        }

        if (this.smallerDiskWheel.nativeElement?.parentElement) {
          this._renderer.setStyle(this.smallerDiskWheel.nativeElement?.parentElement, 'animation', `none`);
        }
      }),
    );
  }
}
