import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgClass, NgIf} from '@angular/common';
import {AccordionDirective} from 'ngx-unificator/directives';
import {TranslatePipe} from '../../translation/translate.pipe';
import {SafePipe} from 'ngx-unificator/pipes';

@Component({
  selector: 'app-accordion-button',
  templateUrl: './accordion-button.component.html',
  styleUrls: ['./accordion-button.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    AccordionDirective,
    TranslatePipe,
    NgIf,
    SafePipe
  ]
})
export class AccordionButtonComponent implements OnInit {
  @ViewChild(AccordionDirective, {static: true}) accordion: AccordionDirective;

  @Input() text: any;

  /**
   * If true use another styles for footer
   */
  @Input() isFooter = false;
  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit() {
    // Manually mark for check to avoid ExpressionChangedAfterItHasBeenCheckedError
    this.cd.detectChanges();
  }
}

