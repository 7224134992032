<div class="container">
  <div class="blog">
    <app-tabs (select)="onSelectTab($event)" [defaultActive]="false" #tab>
      <app-tab-item #news [id]="'news'" [tabTitle]="'t.blog-news' | translate"></app-tab-item>
      <app-tab-item #promotions [id]="'promotions'" [tabTitle]="'t.blog-promotions' | translate"></app-tab-item>
    </app-tabs>
    @for (list$ of lists$; track list$) {
      @if (list$ | async; as list) {
        @if (list.length) {
          <div class="articles-grid">
            @for (article of list; track article) {
              <div class="articles-grid__item">
                <app-blog-article-preview [article]="article"></app-blog-article-preview>
              </div>
            }
          </div>
        } @else {
          <div class="no-results">{{'t.no-result' | translate}}</div>
        }
      } @else {
        <div class="articles-grid">
          @for (article of [1,2,3,4,5]; track article) {
            <div class="articles-grid__item">
              <app-blog-article-preview></app-blog-article-preview>
            </div>
          }
        </div>
      }
    }

    @if (loadMoreVisible) {
      <div class="actions">
        <button class="btn btn--filled-primary big" (click)="updateOffset()">{{ 't.load-more' | translate }}</button>
      </div>
    }
  </div>
</div>
