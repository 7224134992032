<div class="sign-in-container">
  <form class="form" [formGroup]="controller.form" (ngSubmit)="controller.submitForm()" [ngClass]="{'install-app': installApp.showInstallAppRegisterBlock}">
    <i class="close icon-close" (click)="onClose()"></i>
    <div class="form__title form__title--main">{{'t.sign-in' | translate}}</div>
    <div>
      <div>
        @if (controller.is2FAEnabled$ | async) {
          <app-form-input [label]="'t.auth-code' | translate" class="input-item" [control]="controller.input('otp_attempt')">
            <input class="input input--simple" type="text" name="otp_attempt" formControlName="otp_attempt">
          </app-form-input>
        } @else {
          <app-form-input [label]="'t.email' | translate" class="input-item" [control]="controller.input('email')">
            <input class="input input--simple" type="email" name="email" formControlName="email" setValueAutofill>
          </app-form-input>
          <app-form-input [label]="'t.passwd' | translate" class="input-item" [control]="controller.input('password')">
            <i class="icon icon-password password-toggle"></i>
            <input class="input input--simple password" type="password" name="password" PasswordShow
              formControlName="password" setValueAutofill>
            </app-form-input>
          }

        </div>

        <div class="submit-container">
          @if (!controller.loading) {
            <button class="btn btn--filled-primary big" type="submit">{{'t.sign-in' | translate}}</button>
          }
          @if (controller.loading) {
            <app-preloader></app-preloader>
          }
        </div>

        <div class="bottom-links">
          <div class="bottom-links__item">
            <span class="link link--primary" (click)="openRestorePasswordModal()">{{'t.forgot-passwd' | translate}}</span>
          </div>
          @if (!env.isRestrictSignUp) {
            <div class="bottom-links__item">
              <span> {{ 't.have-no-acc' | translate }} </span>
              <span class="link link--primary" (click)="onSignUpClick()">{{ 't.sign-up' | translate }}</span>
            </div>
          }
        </div>
      </div>
    </form>

    @if (installApp.showInstallAppRegisterBlock) {
      <div class="install-app-banner">
        <div class="install-app-banner__wrapper">
          <img class="install-app-banner__logo" src="/assets/img/mobile-app/icon.png" [alt]="seo.metaTitle">
          <div>
            <div class="install-app-banner__text">{{ 't.all-games-bonuses-app' | translate }}</div>
            <button class="btn btn--filled-primary" (click)="installApp.closeDepositBlock()" [routerLink]="['/', 'mobile-app']">{{'t.install' | translate}}</button>
          </div>
        </div>
        <i class="close icon-close" (click)="installApp.closeRegisterBlock()"></i>
      </div>
    }
  </div>
