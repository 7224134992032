@if (offer) {
  <div class="offer">
    <div class="offer__info">
      <h2 class="offer__info_title" [innerHTML]="offer[0]?.Title | safe: 'html'"></h2>
      <div class="offer__info_description" [innerHTML]="offer[0]?.Description | safe: 'html'"></div>
      <div class="offer__info_actions">
        @if (offer[0]?.DiscordBtnLink; as url) {
          <a [href]="url">
            <img src="/assets/img/social/discord.png" [alt]="seo.metaTitle" class="discord">
          </a>
        }
        @if (offer[0]?.TelegramBtnLink; as url) {
          <a [href]="url">
            <img src="/assets/img/social/telegram.png" [alt]="seo.metaTitle" class="telegram">
          </a>
        }
        @if (offer[0]?.TwitterBtnLink; as url) {
          <a [href]="url">
            <img src="/assets/img/social/twitter.png" [alt]="seo.metaTitle" class="twitter">
          </a>
        }
        @if (offer[0]?.InstagramBtnLink; as url) {
          <a [href]="url">
            <img src="/assets/img/social/instagram.png" [alt]="seo.metaTitle" class="instagram">
          </a>
        }
      </div>
    </div>
    <img class="offer__image offer__image_second" [src]="offer[0]?.Image" [alt]="seo.metaTitle">
  </div>
}
