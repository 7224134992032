<app-header [colorDuringSroll]="'#41307E'"></app-header>
<app-side-nav></app-side-nav>

@if (promo$ | async; as promo) {
  <div class="promo" [ngStyle]="{opacity: summer.isLoadedPromo ? '1' : '0'}"
    >
    <div class="top-container">
      <div class="container">
        <div class="header__timer">
          <app-summer-timer [timeLeft]="promo?.unpublishAt"></app-summer-timer>
        </div>
      </div>
      @if ((summer.lootboxPromoBonus || summer.isWelcomeBonusExist) && !summer.currentPrize && !summer.isUserAlreadyActivateBox) {
        <div class="header__title" [innerHTML]="promo?.Title | safe: 'html'"></div>
        <div class="header__spin"> 1 SPIN <br> <span>{{ 't.available-for-you' | translate }}!</span> </div>
      }
      @if (summer.currentPrize) {
        <div class="header__current-prize" [@showHide]>
          <div class="header__current-prize_title" [innerHTML]="promo?.WinTitle | safe: 'html'"></div>
          <div class="header__current-prize_info">
            <img class="header__current-prize_info--image" [src]="summer.currentPrize?.image" alt="Promo prize - wildfortune casino">
            @if (summer.currentPrize?.cash) {
              <div class="header__current-prize_info--prize cash" [innerHTML]="summer.currentPrize?.cash | safe: 'html'"></div>
              @if (summer.currentPrize?.fs_count) {
                <div class="header__current-prize_info--prize fs">+</div>
                <div class="header__current-prize_info--prize fs">{{ summer.currentPrize?.fs_count }} {{ 't.free-spins' | translate }}</div>
              }
            } @else {
              @if (summer.currentPrize?.fs_count) {
                <div class="header__current-prize_info--prize">{{ summer.currentPrize?.fs_count }} <br> <span>{{ 't.free-spins' | translate }}</span></div>
              }
            }
          </div>
          <div class="header__current-prize--desc">{{ 't.visit-account' | translate }}!</div>
          <div class="header__current-prize--action">
            <button (click)="summer.onAction(summer.SummerAction.GET_BONUS)" class="btn btn--primary big">
              {{ 't.get-bonus' | translate }}
            </button>
          </div>
        </div>
      }
      @if (!summer.currentPrize && !summer.lootboxPromoBonus && !summer.isWelcomeBonusExist || summer.isUserAlreadyActivateBox) {
        <div class="header__title" [innerHTML]="promo?.Title | safe: 'html'"></div>
        <div class="header__prize">
          <div class="header__prize_title" [innerHTML]="promo?.RewardTitle | safe: 'html'"></div>
          <div class="header__prize_reward" [innerHTML]="promo?.RewardPrize | safe: 'html'"></div>
        </div>
      }
      @if (!summer.currentPrize) {
        <div class="header__wheel">
          <app-summer-wheel [wheelPrizesImage]="summer?.wheelPrizeImg" #wheelComponent></app-summer-wheel>
        </div>
      }
      @if (!summer.currentPrize) {
        @if (!summer.isUserAlreadyActivateBox) {
          <div class="header__action">
            @if (summer.lootboxPromoBonus || summer.isWelcomeBonusExist) {
              <button (click)="onSpinWheel()" class="btn btn--primary" [disabled]="onSpinClick">
                {{ 't.spin' | translate }}
              </button>
            } @else {
              @if (!user.auth) {
                <button (click)="summer.onAction(summer.SummerAction.PROMO_AUTH)" class="btn btn--primary">
                  {{ (env.isRestrictSignUp ? 't.sign-in' : user.authKeyTranslate) | translate }}
                </button>
              }
              @if (user.auth) {
                <button (click)="summer.onAction(summer.SummerAction.GO_TO_DEPOSIT)" class="btn btn--primary">
                  {{ 't.deposit' | translate }}
                </button>
              }
            }
          </div>
          @if (summer.lootboxPromoBonus || summer.isWelcomeBonusExist) {
            <div class="header__unlock-bonus" [innerHTML]="promo?.RewardTitle | safe: 'html'"></div>
          } @else {
            @if (user.auth) {
              <div class="header__rule">
                {{ promo?.Rule }}
              </div>
            }
            <div class="header__second-rule">
              {{ promo?.SecondRule }}
            </div>
          }
        } @else {
          <div class="header__tomorrow">
            <div class="header__tomorrow--title">
              {{ promo?.TryTomorrowTitle }}
            </div>
            <div class="header__tomorrow--desc">
              {{ promo?.TryTomorrowDescription }}
            </div>
            <div (click)="summer.onAction(summer.SummerAction.GO_TO_BONUSES)" class="header__tomorrow--action">
              {{ 't.check-other-promotions' | translate }}
            </div>
          </div>
        }
      }
    </div>
    <div class="steps-container">
      <div class="container">
        <div class="title-main title-main--steps" [innerHTML]="promo?.StepsTitle | safe: 'html'">{{'t.games' | translate}}</div>
        <div class="steps-container__steps">
          @for (step of promo?.Steps; track step; let i = $index) {
            <div class="steps-container__steps--item">
              <div class="steps-container__steps--item__number">
                {{i + 1}}
              </div>
              <div class="steps-container__steps--item__description">
                {{step?.title}}
              </div>
            </div>
          }
        </div>
        @if (user.auth && (!summer.isUserAlreadyActivateBox && (summer.lootboxPromoBonus || summer.isWelcomeBonusExist) || summer.currentPrize)) {
          <button
            (click)="(summer.lootboxPromoBonus || summer.isWelcomeBonusExist) && !summer.currentPrize ? scrollToWheel() : summer.onAction(summer.SummerAction.GET_BONUS)"
            class="btn big btn--primary">
            {{'t.unlock-monster-wins' | translate}}
          </button>
        }
      </div>
    </div>
    @if (summer?.gameList$ | async; as gamesList) {
      @if (gamesList && gamesList.length) {
        <div class="container container-games">
          <div class="bottom-container__inner">
            <div class="bottom-container__left">
              <div class="title-main title-main--games">{{'t.games' | translate}}</div>
              <div class="games-desc" [innerHTML]="promo?.GamesDescription | safe: 'html'"></div>
              <div class="games-container games-container--desktop">
                <div class="slider__wrapper">
              <app-slider #gamesListSlider [config]="{spacing: 10, autoplay: false, slidesPerView: 5, centered:
                true, breakpoints: summer.gamesBreakpoints}" [slideList]="gamesList[1]">
                    @for (game of gamesList[1]; track game; let i = $index) {
                      <div class="game-grid__item">
                        <app-game-preview [game]="game"></app-game-preview>
                      </div>
                    }
                  </app-slider>
                </div>
              </div>
              <div class="games-container games-container--mobile">
                <div class="container">
                  <div class="slider__wrapper">
                    <app-slider #gamesListSliderMobile [config]="{spacing: 10, slidesPerView: 2}" [slideList]="gamesList[0]">
                      @for (list of gamesList[0]; track list) {
                        <div class="game-grid">
                          @for (game of list; track game; let i = $index) {
                            <div class="game-grid__item">
                              <app-game-preview [game]="game"></app-game-preview>
                            </div>
                          }
                        </div>
                      }
                    </app-slider>
                    <div class="pagination-container">
                      @if (gamesListSlider) {
                        <ng-container *ngTemplateOutlet="gamesListSliderMobile?.paginationElement"></ng-container>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div class="action-btn-mobile">
                <button
                  (click)="!user.auth ? user.authUser() : null"
                  [routerLink]="['/', 'games', 'summer-favorites']"
                  class="btn big btn--primary big">
                  {{user.auth ? ('t.i-want-more' | translate) : ((env.isRestrictSignUp ? 't.sign-in' : user.authKeyTranslate) | translate)}}
                </button>
              </div>
            </div>
          </div>
        </div>
      }
    }
    <ng-container>
      @if (summer.tournaments$ | async; as tournamentsList) {
        <div class="container container-tournaments">
          @if (tournamentsList && tournamentsList.length) {
            <div class="tournaments">
              <p class="title-main">{{ 't.tournaments' | translate }}</p>
              <div class="slider">
                <app-slider #tournamentsSlider [config]="{spacing: 20, autoplay: false, breakpoints: summer.tournamentsBreakpoints}" [slideList]="tournamentsList">
                  @for (item of tournamentsList; track item) {
                    <div class="slide">
                      <div class="slide__item">
                        <app-tournament-preview
                    [tournament]="{
                          title: item.Title + '<br>' + (item.TitleMiddle ? item.TitleMiddle : ''),
                          description: item.Description ? item.Description : '',
                          image: item.Image,
                          imageMob: item.ImageMob,
                          color: item.ColorBgStart,
                          colorInfo: item.ColorBgEnd,
                          link: item.slug,
                          time: item.active ? item.endAt : item.upcoming ? item.startAt : null,
                          timeLabel: item.closed ? ('t.finished' | translate) : '',
                          prizePool: item.PrizePool,
                          active: item.active,
                          upcoming: item.upcoming,
                          closed: item.closed,
                          tournamentGroup: item.TournamentGroup,
                          vip: item.Vip
                        }"
                        ></app-tournament-preview>
                      </div>
                    </div>
                  }
                </app-slider>
                <div class="pagination-container">
                  @if (tournamentsSlider) {
                    <ng-container *ngTemplateOutlet="tournamentsSlider?.paginationElement"></ng-container>
                  }
                </div>
              </div>
            </div>
          }
        </div>
      }
      <div class="container">
        <div class="offers">
          <div class="offers__item" [ngClass]="{'phone-displayed': summer.isPhoneVerified$ | async}">
            <div class="offers__item_title" fontByBlockSize>
              <span [innerHTML]="promo?.TelegramTitle | safe: 'html'"></span>
            </div>
            <div class="offers__item_desc"></div>
            <a class="btn btn--primary" [href]="promo.TelegramUrl">{{ 't.join-now' | translate }}</a>
            <img src="/assets/img/promo/summer/offers/telegram.png" alt="Telegram promo offer - wildfortune casino">
          </div>
          @if (summer.isPhoneVerified$ | async) {
            <div class="offers__item">
              <div class="offers__item_title" fontByBlockSize>
                <span [innerHTML]="promo?.PhoneTitle | safe: 'html'"></span>
              </div>
              <div class="offers__item_desc" [innerHTML]="promo?.PhonePrize | safe: 'html'"></div>
              <button class="btn btn--primary" (click)="summer.onAction(summer.SummerAction.GO_TO_PROFILE)">{{ 't.get-bonus' | translate }}</button>
              <img src="/assets/img/promo/summer/offers/phone.png" alt="Phone promo offer - wildfortune casino">
            </div>
          }
        </div>
      </div>
      <div class="container">
        <div class="works">
          @if (promo?.HowItWorksTerms) {
            <div class="works">
              <div class="accordion">
                <div class="accordion__header" Accordion>
                  <div>{{'t.how-it-works' | translate}}</div>
                  <i class="accordion__icon icon-pointer-down"></i>
                </div>
                <div class="accordion__content">
                  <div [innerHTML]="promo?.HowItWorksTerms | safe: 'html'"></div>
                </div>
              </div>
            </div>
          }
        </div>
        <div class="terms">
          @if (promo?.GeneralTerms) {
            <div class="terms">
              <div class="accordion">
                <div class="accordion__header" Accordion>
                  <div>{{'t.terms-and-cond' | translate}}</div>
                  <i class="accordion__icon icon-pointer-down"></i>
                </div>
                <div class="accordion__content">
                  <div [innerHTML]="promo?.GeneralTerms | safe: 'html'"></div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </ng-container>
  </div>
} @else {
  <div class="preloading"></div>
}

<ng-template #preloading>
  <div class="preloading"></div>
</ng-template>

<app-bottom-navigation></app-bottom-navigation>
<app-footer></app-footer>
