import {Component, Input, OnInit} from '@angular/core';
import {of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import {ToastMessageService} from '../../../../modules/toast-message/toast-message.service';
import {UserBonusesService} from '../../../../services/user/user-bonuses.service';
import {FormsErrorHandlerService} from '../../../../services/forms-error-handler.service';
import {SeoService} from '../../../../services/seo.service';
import { TranslatePipe } from '../../../translation/translate.pipe';
import { FormInputComponent } from '../../../../modules/form-controls/form-input/form-input.component';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-no-deposit-bonus',
    templateUrl: './no-deposit-bonus.component.html',
    styleUrls: ['./no-deposit-bonus.component.scss'],
    standalone: true,
    imports: [ReactiveFormsModule, NgClass, FormInputComponent, TranslatePipe]
})
export class NoDepositBonusComponent implements OnInit {

  @Input() isSmallOneCardDesktopMode: boolean = false;
  @Input() isSmallTwoCardDesktopMode: boolean = false;

  /**
   * Form for send no deposit bonus code
   */
  public bonusCodeForm: UntypedFormGroup = this._fb.group({
    code: ['']
  });

  constructor(
    private _toastMessage: ToastMessageService,
    private _userBonus: UserBonusesService,
    private _formErrors: FormsErrorHandlerService,
    private _fb: UntypedFormBuilder,
    public seo: SeoService
  ) { }

  ngOnInit() {
  }

  /**
   * Send deposit bonus code
   */
  onBonusCodeFormSubmit() {
    this._userBonus.activateCoupon(this.bonusCodeForm.get('code').value).pipe(
      catchError(error => {
        this._toastMessage.error(this._formErrors.ssBackendErrorsToArray(error.error)[0] || 't.went-wrong');
        return of(error);
      }),
      tap(response => {
        this._userBonus.resolveCouponResponseMessage(response);
      }),
    ).subscribe();
  }

}
