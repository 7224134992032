import { CookieService } from 'ngx-unificator/services';
import { delay, distinctUntilChanged, filter, first, map, switchMap, takeWhile, tap } from 'rxjs/operators';
import { PlatformService } from '../../core/services/platform.service';
import { StaticContentService } from '../../core/services/static-content.service';
import { UserService } from '../../core/services/user/user.service';
import { GroupsService } from '../../core/services/groups.service';
import { Router } from '@angular/router';
import { inject } from '@angular/core';
import { ModalRef } from '../../core/modal-v2/modal-ref';
import { UserBonusesService } from '../../core/services/user/user-bonuses.service';
import { TournamentsService } from '../tournaments/tournaments.service';
import { TimeService } from '../../core/services/time.service';
import { StaticPageService } from '../static-page/static-page.service';
import { LootboxService } from '../../core/services/lootbox/lootbox.service';
import { DeviceDetectorService } from 'ngx-unificator/services';
import { CmsApiService } from '../../core/services/api/cms-api.service';
import { GamesService } from '../../core/services/games/games.service';
import { ArrayService } from '../../core/services/array.service';
import { combineLatest, Observable } from 'rxjs';
import { ModalService } from '../../core/modal-v2/modal.service';
import { GlobalEventsService } from '../../core/services/global-events.service';
import { TranslationService } from '../../core/shared/translation/translation.service';
import { ResponseHandlerService } from '../../core/services/response-handler.service';
import { WindowService } from '../../core/services/window.service';
import { LocalHistoryService } from '../../core/services/local-history.service';

export enum EventType {
  First = 'first',
  Second = 'second',
  Third = 'third'
}

export interface HuntModalData {
  Title: string;
  Description: string;
  Prize: string;
  Game: string;
  BonusCode: string;
  BonusGroupCatch: string;
  BonusGroupClaimed: string;
  FinalModalTitle: string;
  FinalModalCondition: string;
}

/**
 * Interface for main promo page
 */
export interface IPromo {
  Title: string;
  PrizeTitle: string;
  PrizeSubtitle: string;
  Description: string;
  BonusModalPrizeTitle: string;
  BonusModalTitle: string;
  BonusModalDescription: string;
  BonusModalInformer: string;
  GamesTitle: string;
  GamesDescription: string;
  Terms: string;
  TopImage: string;
  GroupList: any;
  PrizepoolTitle: string;
  WheelTitle: string;
  WheelDesc: string;
  HuntTitle: string;
  HuntDescription: string;
  HuntSubtitle: string;
  PrizePool: any;
  Steps: any;
  timeLeft: any;
  timeStatus: any;
}

export interface WelcomePromoModal {
  Title: string;
  Description: string;
  LogoImage: string;
  MainImage: string;
  Prize: string;
  ButtonUrl: string;
}

export abstract class BasePromo {

  /**
   * Abstract event name for hunt(halloween, easter-eggs ...)
   */
  public abstract eventName: string;

  /**
   * Event type elements
   */
  public eventType = EventType;

  /**
   * Event collected list
   */
  public eventList: unknown[] = [];

  /**
   * Event Loaded
   */
  private _eventLoaded = null;

  /**
   * Show/hide sidebar memu link
   */
  public linkMenuEnabled: boolean;

  /**
   * Bottom link enabled
   */
  public linkBottomEnabled: boolean;

  /**
   * Promo logo
   */
  public isPromoLogo: boolean;

  /**
   * Data for hunt (text, userGroup, etc)
   */
  public huntModalData: HuntModalData;

  public openedModal: ModalRef;

  public isUserHasActiveFs = false;

  private _static: StaticContentService = inject(StaticContentService);
  private _router: Router = inject(Router);
  private _events: GlobalEventsService = inject(GlobalEventsService);
  public modals: ModalService = inject(ModalService);
  public cookie: CookieService = inject(CookieService);
  public cmsApi: CmsApiService = inject(CmsApiService);
  public games: GamesService = inject(GamesService);
  public array: ArrayService = inject(ArrayService);
  public user: UserService = inject(UserService);
  public time: TimeService = inject(TimeService);
  public page: StaticPageService = inject(StaticPageService);
  public bonuses: UserBonusesService = inject(UserBonusesService);
  public lootbox: LootboxService = inject(LootboxService);
  public device: DeviceDetectorService = inject(DeviceDetectorService);
  public tournaments: TournamentsService = inject(TournamentsService);
  public router: Router = inject(Router);
  public translate: TranslationService = inject(TranslationService);
  public window: WindowService = inject(WindowService);
  public local: LocalHistoryService = inject(LocalHistoryService);
  public platform: PlatformService = inject(PlatformService);
  public responseHandler: ResponseHandlerService = inject(ResponseHandlerService);
  public group: GroupsService = inject(GroupsService);

  constructor() {
  }

  public get eventEnable(): boolean {
    return this._eventLoaded;
  }

  public get isFirstExist(): boolean {
    return this.user.auth && this._eventLoaded && !this.eventList.includes(this.eventType.First);
  }

  public get isSecondExist(): boolean {
    return this.user.auth && this._eventLoaded && !this.eventList.includes(this.eventType.Second);
  }

  public get isThirdExist(): boolean {
    return this.user.auth && this._eventLoaded && !this.eventList.includes(this.eventType.Third);
  }

  public initHuntEvent() {
    if (this.platform.isBrowser) {
      this._static.item({ slug: 'event-' + this.eventName }).pipe(
        tap((response: any[]) => {
          if (response && response.length) {
            this._resolveHuntEvent(response);
          }
        }),
      ).subscribe();
    }
  }

  public addEventToCollection(type: EventType) {
    if (this.platform.isBrowser) {
      this.eventList.push(type);
      this.cookie.set(this.eventName, JSON.stringify(this.eventList), 999, '/');
      if (this.eventList.length === 3) {
        this.group.addToGroup(this.huntModalData.BonusGroupCatch).subscribe();
      }
      this.openHuntModal(type);
    }
  }

  private _resolveHuntEvent(data) {
    if (data[0]) {
      this.huntModalData = data[0];
    }

    if (this.huntModalData && (!this.group.isExistGroup(this.huntModalData.BonusGroupClaimed))) {
      this.cookie.check(this.eventName) ?
        this.eventList = JSON.parse(this.cookie.get(this.eventName)) :
        this.eventList = [];
      this._eventLoaded = true;

    } else {
      this._eventLoaded = false;
    }
  }

  public initSidebarMenuLink() {
    this._static.item({slug: this.eventName + '-side-menu'})
      .pipe(
        tap((response: any[]) => {
          this.linkMenuEnabled = !!(response && response.length);
          this.isPromoLogo = !!(response && response.length);
          if (this.linkMenuEnabled && this.user.auth) {
            this.bonuses.getAllBonuses().subscribe();
          }
        })
      ).subscribe();
  }


  public initWelcomePromoModal() {
    this._static.item({slug: this.eventName + '-welcome-modal'})
      .pipe(
        filter(response => !!response?.length),
        map(response => response[0]),
        switchMap((response) => this._checkShouldOpenWelcomeModal$(response)),
      ).subscribe();
  }

  private _checkShouldOpenWelcomeModal$(modalData: WelcomePromoModal): Observable<boolean> {
    const routesIgnore = ['403', '404', 'register', 'login', 'deposit', 'cashout', 'games', 'promo', 'play'];
    return this._events.routerNavigationEnd$
      .pipe(
        takeWhile(() => !this.cookie.check('welcome-modal-promo'), true),
        distinctUntilChanged(),
        delay(120000),
        filter(() => {
          const hasWelcomeModalCookie = this.cookie.check('welcome-modal-promo');
          const shouldOpenModal = this.user.auth && !hasWelcomeModalCookie;
          return !this.isUserHasActiveFs && shouldOpenModal && !routesIgnore.some(route => this.router.url.includes(route));
        }),
        switchMap(() => this.checkActivatedBonuses(modalData)),
      );
  }

  public onHuntClick() {
    this.user.auth$.pipe(
      tap(auth => {
        if (auth) {
          this._router.navigateByUrl('/').then();
        } else {
          this.user.authUser().then();
        }
      })
    ).subscribe();
  }

  // get EventType() {
  //   return EventType;
  // }

  public abstract openHuntModal(type: EventType): void;
  public abstract checkActivatedBonuses?(data: WelcomePromoModal): Observable<any>;
}
