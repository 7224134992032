import {Component, Input, OnInit} from '@angular/core';
import { SeoService } from '../../../core/services/seo.service';
import { SafePipe } from '../../../core/shared/pipes/safe.pipe';



export interface SocialOffer {
  Title: string;
  Description: string;
  TelegramUrl: string;
  ViberUrl: string;
  Image: any;
  ImageSecond: any;
  ImageMob: any;
  DiscordBtnLink: string;
  InstagramBtnLink: string;
  TwitterBtnLink: string;
  TelegramBtnLink: string;
}

@Component({
    selector: 'app-social-offer',
    templateUrl: './social-offer.component.html',
    styleUrls: ['./social-offer.component.scss'],
    standalone: true,
    imports: [SafePipe]
})
export class SocialOfferComponent implements OnInit {

  /**
   * Social offer
   */
  @Input('offer') offer: SocialOffer[];

  constructor(
    public seo: SeoService
  ) { }

  ngOnInit() {}
}
