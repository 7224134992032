@if (social$ | async; as social) {
  <div class="social__actions">
    @if (social[0]?.DiscordBtnLink; as url) {
      <a [href]="url">
        <img src="/assets/img/social/discord.png" [alt]="seo.metaTitle" class="discord">
      </a>
    }
    @if (social[0]?.TelegramBtnLink; as url) {
      <a [href]="url">
        <img src="/assets/img/social/telegram.png" [alt]="seo.metaTitle" class="telegram">
      </a>
    }
    @if (social[0]?.TwitterBtnLink; as url) {
      <a [href]="url">
        <img src="/assets/img/social/twitter.png" [alt]="seo.metaTitle" class="twitter">
      </a>
    }
    @if (social[0]?.InstagramBtnLink; as url) {
      <a [href]="url">
        <img src="/assets/img/social/instagram.png" [alt]="seo.metaTitle" class="instagram">
      </a>
    }
  </div>
}
<div class="bottom-nav">
  <div class="navigation">
    <a class="nav-btn" [routerLink]="['/', lang.current, 'banking']">{{'t.banking' | translate}}</a>
    <a class="nav-btn" [routerLink]="['/', lang.current, 'responsible-gaming']">{{'t.responsible-gaming' | translate}}</a>
    <a class="nav-btn" [routerLink]="['/', lang.current, 'about']">{{'t.about-us' | translate}}</a>
    <a class="nav-btn" [routerLink]="['/', lang.current, 'blog']">{{'t.blog' | translate}}</a>
    <a class="nav-btn" [routerLink]="['/', lang.current, 'terms-and-conditions']">{{ 't.terms-and-cond' | translate }}</a>
    @if (!env.isSe) {
      <a class="nav-btn" [routerLink]="['/', lang.current, 'bonus-terms']">{{ 't.bonus-terms' | translate }}</a>
    }
    <a class="nav-btn" [routerLink]="['/', lang.current, 'privacy-policy']"> {{ 't.privacy-policy' | translate }}</a>
    <a class="nav-btn" [routerLink]="['/', lang.current, 'cookie-policy']"> {{ 'link.cookie-policy' | translate }}</a>
    <a class="nav-btn" [routerLink]="['/', lang.current, 'faq']">{{'t.faq' | translate}}</a>
    <a class="nav-btn" target="_blank" href="https://www.samuraipartners.com"> {{ 't.becom-aff' | translate }}</a>
    <a class="nav-btn" [routerLink]="['/', lang.current, 'mobile-app']"> {{ 't.install-app' | translate }}</a>
  </div>
</div>
