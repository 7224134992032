<div class="game" (click)="toggleOverlay()">
  <div class="game__image">
    <div class="badge-list" [ngClass]="{'small-mode': smallMode, 'mystery': game['mystery']}">
      @if (game['grizzlyJackpot']) {
        <img  class="badge-list__item jackpot" src="/assets/img/jackpot/jackpot-badge.png"  [alt]="seo.metaTitle">
      }
      @if (game['new']) {
        <img  class="badge-list__item" src="/assets/svg/new-game.svg"  [alt]="seo.metaTitle">
      }
      @if (game['pre_release']) {
        <img  class="badge-list__item prerelease" src="/assets/svg/pre-release.svg" [alt]="seo.metaTitle">
      }
      @if (game['popular'] && !game['new']) {
        <img  class="badge-list__item" src="/assets/svg/hot-game.svg" [alt]="seo.metaTitle">
      }
      @if (game['mystery']) {
        <img  class="badge-list__item mystery-drop" src="/assets/img/mystery-drop.png" [alt]="seo.metaTitle">
      }
      @if (game['tournament']) {
        <div class="badge-list__item tournament"
          ngxTippy
          [tippyProps]="tippyPropsTournament"
          [class]="[breakPoint.isDesktop ? '' : 'hide']"
          >
          <img  src="/assets/svg/tournament.svg" alt="" >
        </div>
      }

    </div>
    @if (game['drops_and_wins']) {
      <img  class="badge-list__item" [ngClass]="{'drops': game['drops_and_wins']}" src="/assets/svg/drops_and_wins.svg" [alt]="seo.metaTitle">
    }
    @if (!smallMode && (user.auth && game.externalData.real[user.currentCurrency?.currency]?.jackpot || !user.auth && (game.externalData.real[env.env.currency.short] || game.externalData.real['EUR'])?.jackpot)) {
      <app-jackpot-badge [game]="game"></app-jackpot-badge>
    }

    <img ImgDimension defaultImage [alternative]="game['bgSrc']" [default]="defaultImageType.GAME" class="game__image-main" draggable="false" [attr.data-src]="game['imgSrc']" width="30" height="60" [alt]="seo.metaTitle">

    <div [ngClass]="{'overlay-visible': isToggleOverlayForDevice}" class="game__overlay">
      <a class="seo-link" [routerLink]="['/', lang.current, 'play', game?.slug]"></a>

      <a class="game__circle-play" [routerLink]="['/', lang.current, 'play', game?.slug]" (click)="openGame(modes.real); $event.stopPropagation()">
        <i class="game__play icon-play"></i>
      </a>
      @if (!user.auth && !game['nodemo'] && !game['live']) {
        <a [routerLink]="['/', lang.current, 'play', game?.slug]" class="game__circle-fun" (click)="openGame(modes.demo); $event.stopPropagation()">
          <div class="game__fun">{{'t.try-fun' | translate}}</div>
        </a>
      }
      <div class="game__circle-favourite">
        <i class="game__favorite icon-favourite" [ngClass]="{
              'icon-favourite': !game['favorite'],
              'icon-favourite_hover': game['favorite']
              }" (click)="favorite.toggleFavorite(game); $event.stopPropagation()"></i>
      </div>
    </div>
  </div>

  @if (!hideInfo) {
    <div>
      <div class="game__title" (click)="openGame(modes.real); $event.stopPropagation()">{{ game['name'] }}</div>
      <div class="game__provider" [routerLink]="['/', 'games']" [queryParams]="{provider: game['provider']?.alias}"> {{ game['provider']?.name }}</div>
      @if (smallMode && (user.auth && game.externalData.real[user.currentCurrency?.currency]?.jackpot || !user.auth && (game.externalData.real[env.env.currency.short] || game.externalData.real['EUR'])?.jackpot)) {
        <app-jackpot-badge [game]="game" [smallMode]="true" class="small-mode"></app-jackpot-badge>
      }
    </div>
  }
</div>

