import {Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {TimeService} from '../../../../services/time.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {takeUntil} from 'rxjs/operators';
import { TranslatePipe } from '../../../translation/translate.pipe';


@UntilDestroy()
@Component({
    selector: 'app-bonus-timer',
    templateUrl: './bonus-timer.component.html',
    styleUrls: ['./bonus-timer.component.scss'],
    standalone: true,
    imports: [TranslatePipe]
})
export class BonusTimerComponent implements OnInit, OnDestroy {
  @Input() timeLeft: Date;
  timeLive: any;

  /**
   * Emits when component destroyed
   */
  private _alive$: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(
    private _time: TimeService
  ) {
  }

  ngOnInit() {
    if (this.timeLeft) {
      this.timeLeft = this.timeLive instanceof Date ? this.timeLeft : new Date(this.timeLeft);
      this._time.currentDate$()
        .pipe(
          untilDestroyed(this),
          takeUntil(this._alive$)
        )
        .subscribe(() => {
          this.timeLive = this._time.timeDiff(this.timeLeft);
        });
    } else {
      this.timeLive = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        daysLabel: 't.days',
        hoursLabel: 't.hrs',
        minLabel: 't.mins',
        secLabel: 't.secs'
      };
    }

  }

  ngOnDestroy(): void {
    this._alive$.next(false);
    this._alive$.complete();
  }
}
