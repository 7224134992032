<form class="bonus" [formGroup]="bonusCodeForm" (ngSubmit)="onBonusCodeFormSubmit()"
      [ngClass]="{'small-two-card': isSmallTwoCardDesktopMode, 'small-one-card': isSmallOneCardDesktopMode}">
  <div class="bonus__info">
    <div class="bonus__text">{{ 't.do-you-have-bonus' | translate }}</div>
    <div class="bonus__input">
      <app-form-input>
        <input [placeholder]="'t.bonus-code' | translate" formControlName="code" type="text">
      </app-form-input>
      <button type="submit" [disabled]="!bonusCodeForm.get('code').value" class="bonus__input_action btn btn--primary">{{'t.send' | translate}}</button>
    </div>
  </div>
  <div class="bonus__image">
    <img src="/assets/img/bonuses/no-deposit.png" [alt]="seo.metaTitle">
  </div>
</form>

