import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Observable } from 'rxjs';
import {filter, first, map, tap} from 'rxjs/operators';
import { BlogService } from '../blog.service';
import { isArray } from 'src/app/core/helpers/utils';
import {ActivatedRoute, Router} from '@angular/router';
import {TabsComponent} from '../../../core/modules/tabs/tabs.component';
import {TabItemComponent} from '../../../core/modules/tabs/tab-item.component';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {PlatformService} from '../../../core/services/platform.service';
import {UserService} from '../../../core/services/user/user.service';
import { TranslatePipe } from '../../../core/shared/translation/translate.pipe';
import { BlogArticlePreviewComponent } from '../blog-article-preview/blog-article-preview.component';
import { AsyncPipe } from '@angular/common';

export enum BlogCategory {
  PROMOTIONS = 3,
  NEWS = 4,
}

@UntilDestroy()
@Component({
    selector: 'app-blog-articles-list',
    templateUrl: './blog-articles-list.component.html',
    styleUrls: ['./blog-articles-list.component.scss'],
    standalone: true,
    imports: [TabsComponent, TabItemComponent, BlogArticlePreviewComponent, AsyncPipe, TranslatePipe]
})
export class BlogArticlesListComponent implements OnInit, OnDestroy {

  @ViewChild('tab') tab: TabsComponent;
  @ViewChild('news') news: TabItemComponent | any;
  @ViewChild('promotions') promotions: TabItemComponent;

  /**
   * Required template fields for rendering articles list
   */
  private readonly _requiredTemplateFields: Array<string> = ['Title', 'Description', 'Image', 'PreviewImage', 'Video', 'OnlyAuth'];

  /**
   * Default articles limit per request
   */
  private readonly _limit = 5;

  /**
   * Current offset
   */
  private _offset = 0;

  /**
   * Is load more button visible
   */
  private _loadMoreVisible: boolean;

  private _activeTab: string;

  /**
   * Blog articles lists from CMS
   */
  public lists$: Array<Observable<any>> = [];

  constructor(
    private _blog: BlogService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _platform: PlatformService,
    private _user: UserService
  ) { }

  /**
   * Access to _loadMoreVisible from template
   */
  get loadMoreVisible(): boolean {
    return this._loadMoreVisible;
  }

  ngOnInit() {
    // this.searchArticles();
    this._handleQuery();
    this._initCurrentTab();
  }

  ngOnDestroy(): void {
  }

  /**
   * Update current articles offset
   */
  public updateOffset() {
    this._offset++;
    this.searchArticles();
  }

  /**
   * Search articles by provided filter
   */
  public searchArticles(data?) {
    this.lists$.push(this._blog.list({
      'field[]': this._requiredTemplateFields,
      type: data === 'news' ? BlogCategory.NEWS : BlogCategory.PROMOTIONS,
      limit: this._limit,
      offset: this._limit * this._offset
    }).pipe(
      map(list => {
        return data === 'news' ? list : list?.filter(b => {
          if (this._user.auth && b['OnlyAuth'] === '1' || this._user.auth && b['OnlyAuth'] === '') {
            return true;
          } else if(!this._user.auth && b['OnlyAuth'] === '1') {
            return false;
          } else {
            return b;
          }
        });
      }),
      tap(list => {
        if (isArray(list)) {
          this._loadMoreVisible = list.length === this._limit;
        }
      })
    ));
  }

  private _handleQuery() {
    this._route.queryParams.pipe(
      untilDestroyed(this),
      tap(data => {
        if (!data?.type) {
          this.onSelectTab({id: 'news'});
        } else {
          this.onSelectTab({id: data?.type});
        }
        this.lists$ = [];
        this._offset = 0;
        this._activeTab = !data?.type ? 'news' : data?.type;
        this.searchArticles(data?.type);
      }),
    ).subscribe();
  }

  onSelectTab($event: any) {
    console.log($event);
    this._router.navigate([], {replaceUrl: true, queryParams: {type: $event?.id}});
  }


  private _initCurrentTab() {
    if (this._platform.isBrowser) {
      this._route.queryParams.pipe(
        first(),
        tap((data) => {
          setTimeout(() => {
            this.tab.selectTab(data?.type === 'promotions' ? this.promotions : this.news);
          }, 400);
        })
      ).subscribe();
    }
  }
}
