import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Observable, of} from 'rxjs';
import {BonusStage} from '../../../../services/user/data/user-bonuses.data';
import {catchError, filter, first, switchMap, tap} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ToastMessageService} from '../../../../modules/toast-message/toast-message.service';
import {ModalService} from '../../../../modal-v2/modal.service';
import {ArrayService} from '../../../../services/array.service';
import {UserBonusesService} from '../../../../services/user/user-bonuses.service';
import {OffersService} from '../../../../../page/offers/offers.service';
import {LootboxService} from '../../../../services/lootbox/lootbox.service';
import {GamesService} from '../../../../services/games/games.service';
import {BreakpointsService} from '../../../../services/breakpoints.service';
import {TranslationService} from '../../../translation/translation.service';
import { TranslatePipe } from '../../../translation/translate.pipe';
import { PreloaderComponent } from '../../preloader/preloader.component';
import { NoDepositBonusComponent } from '../no-deposit-bonus/no-deposit-bonus.component';
import { BonusCardComponent } from '../bonus-card/bonus-card.component';
import { SliderComponent } from '../../../../modules/slider/slider.component';
import { NgClass, NgTemplateOutlet, NgStyle, AsyncPipe } from '@angular/common';

export enum BONUSES_LIST_PLACE {
  ACCOUNT = 'account',
  BONUSES = 'bonuses'
}

@UntilDestroy()
@Component({
    selector: 'app-bonuses-slider',
    templateUrl: './bonuses-slider.component.html',
    styleUrls: ['./bonuses-slider.component.scss'],
    standalone: true,
    imports: [SliderComponent, BonusCardComponent, NgClass, NgTemplateOutlet, NoDepositBonusComponent, NgStyle, PreloaderComponent, AsyncPipe, TranslatePipe]
})
export class BonusesSliderComponent implements OnInit, OnDestroy, OnChanges {

  @Input() place: BONUSES_LIST_PLACE = BONUSES_LIST_PLACE.ACCOUNT;
  @Input() displayBonusCode: boolean = true;

  public BONUSES_LIST_PLACE = BONUSES_LIST_PLACE;

  /**
   * Bonuses list
   */
  public bonuses$: Observable<any>;

  private GAMES_INFO_LIMIT = 100;

  /**
   * Slider config
   */
  public sliderConfig = {
    breakpoints: {
      '(min-width: 992px)': {
        slidesPerView: 2,
        centered: false,
        spacing: 40,
      },
      '(max-width: 992px)': {
        slidesPerView: 2,
        centered: false,
        spacing: 20,
      },
      '(max-width: 767px)': {
        slidesPerView: 1.5,
        centered: false,
        spacing: 20,
      },
      '(max-width: 325px)': {
        slidesPerView: 1.4,
        centered: false,
        spacing: 20
      },
    },
    loop: true,
    autoplay: false
  };

  public isDesktop: boolean;

  constructor(
    private _toastMessage: ToastMessageService,
    private _modals: ModalService,
    private _array: ArrayService,
    private _bonuses: UserBonusesService,
    private _offers: OffersService,
    private _lootbox: LootboxService,
    private _translates: TranslationService,
    private _games: GamesService,
    private _breakpoints: BreakpointsService
  ) { }

  ngOnInit() {
    this.isDesktop = !this._breakpoints.belowSmallDesktop;
    this._updateBonusList();

    this._bonuses.updateBonuses$.pipe(
      untilDestroyed(this),
      filter(isUpdated => !!isUpdated),
      tap(() => {
        this._lootbox.loadUserLoobox().subscribe();
        this._updateBonusList();
      }),
    ).subscribe();

    this._translates.loading$.pipe(
      untilDestroyed(this),
      filter(loading => !loading),
      tap(() => {
        this._lootbox.loadUserLoobox().subscribe();
        this._updateBonusList();
      })
    ).subscribe();
  }


  ngOnDestroy() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.place && changes.place.currentValue === this.BONUSES_LIST_PLACE.BONUSES) {
      this.sliderConfig['breakpoints']['(min-width: 992px)'].slidesPerView = 3;
      this.sliderConfig['breakpoints']['(min-width: 992px)'].spacing = 30;
    }
  }

  get Stage() {
    return BonusStage;
  }

  /**
   * Cancel provided bonus
   *
   * @param bonus
   */
  cancel(bonus: any) {
    const modal = this._modals.open('BONUS_CANCEL_CONFIRM');
    let path;
    if (this._bonuses.isFSBonus(bonus)) {
      path = this._bonuses.cancelFreeSpins(bonus.id);
    }
    else if (this._bonuses.isLootbox(bonus)) {
      path = this._lootbox.cancelLootbox(bonus.id);
    }
    else {
      path = this._bonuses.cancelBonus(bonus.id);
    }

    modal.onResult().pipe(
      first(),
      filter(confirm => !!confirm),
      switchMap(() => {
        bonus.loading = true;
        return path;
      }),
      tap(() => {
        this._toastMessage.success('t.bonus-canceled');
        this._updateBonusList();
      }),
      catchError(error => {
        this._toastMessage.error('t.error');
        return of(error);
      })
    ).subscribe(() => {
      bonus.loading = false;
    });
  }

  /**
   * Activate provided bonus
   *
   * @param bonus
   */
  activate(bonus: any) {
    bonus.loading = true;
    let path;
    if (this._bonuses.isFSBonus(bonus)) {
      path = this._bonuses.activateFreeSpins(bonus.id);
    } else {
      path = this._bonuses.activateBonus(bonus.id);
    }

    path.pipe(
      tap(() => {
        bonus.pending_activation = true;
        this._toastMessage.success('t.few-moments');
      }),
      catchError(error => {
        this._toastMessage.error('t.error');
        return of(error);
      })
    ).subscribe(() => {
      bonus.loading = false;
    });
  }

  /**
   * Update current bonus list
   *
   * @private
   */
  private _updateBonusList() {
    this.bonuses$ = this._bonuses.getAllBonuses();
  }

  /**
   * Open claim bonuses modal
   */
  public async openClaimBonusesModal() {
    const component = await import('../../../../../core/modal-v2/components/lazy/claim-bonuses-modal/claim-bonuses-modal.component');
    await this._modals.openLazy(component?.ClaimBonusesModalComponent, {
      template: 'CLEAR',
      closeAfterRouteChange: true
    });
  }
}
